import request from "@network/index";
import { ErrorResponseType } from "@network/types";
import { useMutation } from "react-query";
import { CommonCreateBodyKeyType } from "src/types/common";
import useRouter from "./useRouter";
import { imageUploadCategoryCheck } from "@utils/data-fns";
import { requestImageUpload } from "@apis/common";
import useGlobalModal from "./useGlobalModal";
import useGlobalNoti from "./useGlobalNoti";

interface UseCommonEditProps {
  editUrl: string;
  createBodyKey: CommonCreateBodyKeyType;
}

const useCommonEdit = <T>({ editUrl, createBodyKey }: UseCommonEditProps) => {
  const { goBack, query, pathname } = useRouter();
  const { showAlertModal } = useGlobalModal();
  const { showEditNoti } = useGlobalNoti();

  const id = query && query.id ? query.id : "";

  const requestUpdate = async <T>(body: T) => {
    const values = body as any;

    const checkBody = createBodyKey?.reduce((acc: any, curr: any) => {
      const currValue = values[curr];
      const isBannerExposureLocation = curr === "bannerExposureLocation";

      if (currValue !== undefined && !!currValue && !isBannerExposureLocation) {
        acc[curr] = currValue;
      } else if (
        // isExposed : 노출 여부 - 공통
        // isPriorityExposed : 상단 고정 여부 - 공지사항
        (curr === "isExposed" && !currValue) ||
        (curr === "isPriorityExposed" && !currValue)
      ) {
        acc[curr] = false;
      } else if (isBannerExposureLocation) {
        //  띠 배너 일 경우에만 노출 위치 포함
        if (values.category === "banner") {
          acc[curr] = currValue;
        } else delete acc[curr];
      }
      return acc;
    }, {});

    let imageUrl;

    if (typeof values.thumbnail === "object") {
      const imageFile = values.thumbnail.originFileObj;
      const category = imageUploadCategoryCheck(pathname);

      const uploadData = { imageFile, category };

      imageUrl = await requestImageUpload(uploadData);
    }

    const detailId = id && +id;

    const contentCategory = values?.contentCategory;

    let customBody = { ...checkBody };

    if (pathname.includes("banner")) {
      customBody = {
        id: detailId,
        ...customBody,
        ...(imageUrl && { thumbnail: imageUrl }),
      };
    }

    if (pathname.includes("faq")) {
      customBody = {
        id: detailId,
        ...customBody,
      };
    }

    if (pathname.includes("event")) {
      customBody = {
        id: detailId,
        ...customBody,
        category: contentCategory,
        ...(contentCategory === "url" && {
          urls: [{ url: values?.url }],
        }),
        ...(contentCategory === "content" && {
          content: values?.content,
        }),
        ...(contentCategory === "button" && {
          urls: values?.urls.map(
            (url: { title: string; url: string }, idx: number) => {
              return { ...url, displayOrder: idx };
            },
          ),
        }),
        ...(imageUrl && { thumbnail: imageUrl }),
      };
    }

    if (pathname.includes("notice")) {
      customBody = {
        id: detailId,
        ...customBody,
        ...(imageUrl && { thumbnail: imageUrl }),
      };
    }

    if (pathname.includes("charger")) {
      customBody = {
        id: detailId,
        ...customBody,
        category: contentCategory,
        ...(imageUrl && { thumbnail: imageUrl }),
      };
    }

    if (pathname.includes("charging")) {
      customBody = {
        id: detailId,
        ...customBody,
        category: contentCategory,
        ...(imageUrl && { thumbnail: imageUrl }),
      };
    }

    if (pathname.includes("app")) {
      customBody = {
        id: detailId,
        ...customBody,
        releaseNote: values?.releaseNote ?? "",
        isForcedUpdate: values?.isForcedUpdate === "업데이트" ? true : false,
      };
    }

    await request<T, void>({
      method: "put",
      url: editUrl,
      requestBody: customBody,
    });
  };
  const { mutate: editMutation } = useMutation<void, ErrorResponseType, T>(
    requestUpdate,
    {
      onSuccess: () => {
        showEditNoti({});
        goBack();
      },
      onError: (error) => {
        console.error("📕 >>>> ERROR", error);
        if (
          error.message.includes("Token is not active") ||
          error.message.includes(
            "Refresh toked issued before the client session started",
          )
        )
          return;
        showAlertModal({
          title: error.message ?? "에러가 발생했습니다.",
        });
      },
    },
  );

  return { editMutation };
};

export default useCommonEdit;
