import GlobalModal from "@components/molecules/Modal/GlobalModal";
import GlobalNoti from "@components/molecules/Noti/GlobalNoti";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import LayoutRoutes from "@routes/LayoutRoutes";
import GlobalStyles from "@styles/GlobalStyles";
import { Route, Routes } from "react-router-dom";
import { Home, PrivateRoutes } from "@constants/urls";
import Landing from "./pages/Landing";
import { PRIVATE_ROUTES } from "./routes";
import ErrorPage from "@pages/ErrorPage";
import { useEffect } from "react";
import PreView from "@pages/PreView";

function App() {
  const { pathname } = useRouter();

  const { hideModal } = useGlobalModal();

  useEffect(() => {
    if (pathname) {
      hideModal();
    }
  }, [hideModal, pathname]);

  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path={Home} element={<Landing />} />

        <Route element={<LayoutRoutes />}>
          {PRIVATE_ROUTES.map(({ path, element }) => {
            return <Route key={path} path={path} element={element} />;
          })}
        </Route>
        <Route path={PrivateRoutes.PreView} element={<PreView />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
      <GlobalModal />
      <GlobalNoti />
    </>
  );
}

export default App;
