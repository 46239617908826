import {
  requestFetchFaq,
  requestUpdateSortedCategory,
  requestUpdateSortedContents,
} from "@apis/faq";
import {
  UpdateSortedCategoryRequest,
  UpdateSortedContentsRequest,
} from "@apis/faq/types";
import { ICONS } from "@assets/icons";
import IconBox from "@components/atoms/IconBox";
import { useFetchFaqCategoryOption } from "@hooks/useFetchFaqCategoryOption";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalNoti from "@hooks/useGlobalNoti";
import { colors, typo } from "@styles/index";
import {
  CommonElementType,
  RenderCommonElementByType,
} from "@utils/DynamicComponentUtils";
import { faqCategoriesFilter } from "@utils/data-fns";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useMutation, useQuery } from "react-query";
import { CommonContentsRenderType, CommonOptionType } from "src/types/common";
import styled, { css } from "styled-components";
import BaseModal from "../BaseModal";
import TwoButtonModal from "../TwoButtonModal";
import DataEmpty from "@components/atoms/DateEmpty";
import Button from "@components/atoms/Button";

export interface FAQOrderManagementModalProps {
  onCancel?: () => void;
  title: string;
  subTitle?: string;
  type: "sortedCategories" | "sortedContents";
}

interface CategoryOptionType extends CommonContentsRenderType {
  placeholder: string;
}

const FAQOrderManagementModal: React.FC<FAQOrderManagementModalProps> = ({
  title,
  subTitle,
  type,
}) => {
  const { hideModal } = useGlobalModal();
  const { showEditNoti } = useGlobalNoti();
  const [contentsFetchCode, setContentsFetchCode] = useState<string>("");
  const [disPlayOrderData, setDisPlayOrderData] = useState<CommonOptionType[]>(
    [],
  );

  const [isCancelModal, setIsCancelModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      category1: "",
      category2: "",
      category3: "",
    },
    onSubmit: () => {},
  });

  // 카테고리 별 코드 저장
  const selectedCategory1 = useRef("");
  const selectedCategory2 = useRef("");
  const initCategoryOptionCode = "NOTF00";

  // 분류별 카테고리 조회
  const { categoryOption: categoryCustomOption1 } = useFetchFaqCategoryOption(
    initCategoryOptionCode,
  );
  const {
    categoryOption: categoryCustomOption2,
    setCategoryOption: setCategoryCustomOption2,
  } = useFetchFaqCategoryOption(formik.values.category1);
  const {
    categoryOption: categoryCustomOption3,
    setCategoryOption: setCategoryCustomOption3,
  } = useFetchFaqCategoryOption(formik.values.category2);

  const { data: initContents } = useQuery(
    ["fetch-sorted-contents", contentsFetchCode],
    () => requestFetchFaq(type, contentsFetchCode),
    {
      onSuccess: (data) => {
        const customOption = data.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        });

        setDisPlayOrderData(customOption);
      },
      onError: () => {
        setDisPlayOrderData([]);
      },
      enabled:
        type === "sortedContents" ||
        (type === "sortedCategories" && !!formik.values.category3),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  // type 이 sortedCategories 일 경우
  const sortedCategoriesOption: CategoryOptionType[] = [
    {
      label: "대분류",
      name: "category1",
      type: CommonElementType.SELECT,
      placeholder: "대분류를 선택해주세요",
      options: categoryCustomOption1,
    },
    {
      label: "중분류",
      name: "category2",
      type: CommonElementType.SELECT,
      placeholder: "중분류를 선택해주세요",
      options: categoryCustomOption2,
    },
    {
      label: "소분류",
      name: "category3",
      type: CommonElementType.SELECT,
      placeholder: "소분류를 선택해주세요",
      options: categoryCustomOption3,
    },
  ];

  // 카테고리 순번 관리 API
  const { mutate: updateSortedCategory } = useMutation<
    unknown,
    unknown,
    UpdateSortedCategoryRequest,
    unknown
  >("update-sorted-category", requestUpdateSortedCategory, {
    onSuccess: () => {
      showEditNoti({
        text: "탭 순번이 변경되었습니다.",
      });
      hideModal();
    },
  });

  // 질문 순번 관리 API
  const { mutate: updateSortedContents } = useMutation<
    unknown,
    unknown,
    UpdateSortedContentsRequest,
    unknown
  >("update-sorted-contents", requestUpdateSortedContents, {
    onSuccess: () => {
      hideModal();
      showEditNoti({
        text: "질문 순번이 변경되었습니다.",
      });
    },
  });

  // 카테고리 변경 시 하위 카테고리 초기화
  const handleCategoryChange = (categoryCode: string) => {
    if (selectedCategory1.current !== categoryCode) {
      selectedCategory2.current = "";
      formik.setFieldValue("category2", "");
      formik.setFieldValue("category3", "");
    }

    selectedCategory1.current = categoryCode;
  };

  // 중분류 변경 시 하위 카테고리 초기화
  const handleSubCategoryChange = (categoryCode: string) => {
    if (selectedCategory2.current !== categoryCode) {
      formik.setFieldValue("category3", "");
    }

    selectedCategory2.current = categoryCode;
  };

  // 저장
  const handleSave = () => {
    // type 이 sortedCategories 일 경우

    const { category1, category2, category3 } = formik.values;
    let customBody;

    if (!category3) {
      customBody = {
        parentCode:
          category3 || category2 || category1 || initCategoryOptionCode,
        categoryDisplayOrders: disPlayOrderData?.map(({ value }, index) => {
          return {
            code: value!,
            displayOrder: index,
          };
        }),
      };

      updateSortedCategory(customBody as UpdateSortedCategoryRequest);
    } else if (category3) {
      customBody = {
        faqDisplayOrders: disPlayOrderData?.map(({ value }, index) => {
          return {
            id: +value!,
            displayOrder: index,
          };
        }),
      };

      updateSortedContents(customBody);
    }
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;

      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        source.index === destination.index
      )
        return;

      const newItems = Array.from(disPlayOrderData);

      const pickData = disPlayOrderData[source.index];
      newItems.splice(source.index, 1);
      newItems.splice(destination?.index, 0, pickData);
      setDisPlayOrderData(newItems);
    },
    [disPlayOrderData],
  );

  const onClose = () => {
    const { category1, category2, category3 } = formik.values;

    const customInitContents = initContents?.map((item) => ({
      label: item.title,
      value: item.id,
    }));

    const currentCategoryOptions =
      category1 && category2 && category3
        ? customInitContents
        : category1 && category2 && !category3
        ? categoryCustomOption3
        : category1 && !category2 && !category3
        ? categoryCustomOption2
        : categoryCustomOption1;

    const isOrderDisPlayOrderDataSame = faqCategoriesFilter(
      currentCategoryOptions!,
    )?.every((item, index) => item.value === disPlayOrderData[index].value);

    if (!isOrderDisPlayOrderDataSame) {
      setIsCancelModal(true);
    } else {
      hideModal();
    }
  };

  // 대분류 변경 시 하위 카테고리 초기화
  useEffect(() => {
    if (formik.values.category1) {
      // 선택한 대분류가 있을 경우
      handleCategoryChange(formik.values.category1);
    } else {
      // 전체 선택시 하위 option 초기화
      setCategoryCustomOption2([]);
      setCategoryCustomOption3([]);
    }

    // 전체 선택시 하위 카테고리 초기화
    formik.setFieldValue("category2", "");
    formik.setFieldValue("category3", "");
  }, [formik.values.category1]);

  // 중분류 변경 시 하위 카테고리 초기화
  useEffect(() => {
    if (formik.values.category2) {
      // 선택한 중분류가 있을 경우
      handleSubCategoryChange(formik.values.category2);
    } else {
      // 전체 선택시 하위 option 초기화
      setCategoryCustomOption3([]);
    }

    // 전체 선택시 하위 카테고리 초기화
    formik.setFieldValue("category3", "");
  }, [formik.values.category2]);
  // 질문별 순번관리 - 질문 조회 코드 저장
  useEffect(() => {
    if (type === "sortedContents") {
      setContentsFetchCode(formik.values.category2);
    }
  }, [formik.values.category2]);

  // 카테고리 순번 리스트 조회후 저장
  useEffect(() => {
    if (type === "sortedCategories") {
      // categroy1의 값이 없을 경우에 customInitialOption를 저장하고, 있을 경우에는 categoryCustomOption1을 저장
      const { category1, category2, category3 } = formik.values;
      let selectedOption = null;

      if (!category1 && categoryCustomOption1.length > 0) {
        selectedOption = categoryCustomOption1;
      }
      if (category1 && !category2 && categoryCustomOption2.length > 0) {
        selectedOption = categoryCustomOption2;
      }
      if (category2 && !category3 && categoryCustomOption3.length > 0) {
        selectedOption = categoryCustomOption3;
      }

      if (selectedOption) {
        setDisPlayOrderData(faqCategoriesFilter(selectedOption));
      }

      if (category3) {
        setContentsFetchCode(category3);
      }
    }
  }, [
    categoryCustomOption1,
    categoryCustomOption2,
    categoryCustomOption3,
    formik.values,
    type,
  ]);

  return (
    <>
      <BaseModal
        isOpen
        modalWidth={50}
        disableModalPadding
        modalType={null}
        handleClose={onClose}
      >
        <div>
          <ModalHeader>
            <HeaderTitle>{title ?? `타이틀`}</HeaderTitle>
            <CloseIconWrap>
              <IconBox
                src={ICONS.MODAL_ICON.CLOSE}
                width={24}
                height={24}
                isCursorPointer
                onClick={onClose}
              />
            </CloseIconWrap>
          </ModalHeader>
          <ModalContentWrap>
            <CategoryBox>
              {sortedCategoriesOption.map(({ label, ...res }, idx) => {
                return (
                  <div key={`category_${idx}`}>
                    <span>{label}</span>
                    {RenderCommonElementByType({
                      formik,
                      ...res,
                    })}
                  </div>
                );
              })}
            </CategoryBox>

            <ContentWrap>
              <ContentTitle>
                <span style={{ marginRight: 20 }}>NO.</span>
                {subTitle ?? "목록"}
              </ContentTitle>
              {/* table? */}
              {/* data 어떻게 움직일지,,,, */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="data-list"
                  // direction="horizontal"
                  // type="column"
                >
                  {/* ul */}
                  {(provided) => {
                    return (
                      <ContentsDataWrap
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {disPlayOrderData.length > 0 ? (
                          disPlayOrderData.map(({ value, label }, idx) => {
                            return (
                              <Draggable
                                draggableId={`${value}_Data_items`}
                                key={value + "_Data_items"}
                                index={idx}
                              >
                                {(provided, snapshot) => {
                                  const INIT_POSIOTION_HEIGHT = 260;
                                  const ITEM_HEIGHT = 62;
                                  const ITEM_POSITION =
                                    INIT_POSIOTION_HEIGHT + ITEM_HEIGHT * idx;

                                  return (
                                    <ContentItemWrap
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      isDragging={snapshot.isDragging}
                                      ref={provided.innerRef}
                                      style={{
                                        ...provided.draggableProps.style,
                                        top: `${ITEM_POSITION}px`,
                                        left: "24px",
                                      }}
                                    >
                                      <IconBox
                                        isCursorPointer
                                        src={ICONS.MODAL_ICON.DRAG_DROP}
                                        width={24}
                                        height={24}
                                      />
                                      <span className="itemNumber">
                                        {idx + 1}
                                      </span>
                                      <span className="itemContent">
                                        {label}
                                      </span>
                                    </ContentItemWrap>
                                  );
                                }}
                              </Draggable>
                            );
                          })
                        ) : (
                          <div style={{ padding: "90px" }}>
                            <DataEmpty />
                          </div>
                        )}
                        {provided.placeholder}
                      </ContentsDataWrap>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </ContentWrap>
          </ModalContentWrap>
          <ModalFooter>
            <div>
              <Button
                buttonSize="lager"
                buttonType="text"
                text="닫기"
                onClick={onClose}
              />
              <Button buttonSize="lager" text="저장하기" onClick={handleSave} />
            </div>
          </ModalFooter>
        </div>
      </BaseModal>
      {isCancelModal && (
        <TwoButtonModal
          title={`작성한 내용을 저장하지 않고,\n취소하시겠어요?`}
          secondButtonText="확인"
          firstButtonText="닫기"
          onConfirm={() => {
            hideModal();
            setIsCancelModal(false);
            setDisPlayOrderData([]);
          }}
          onCancel={() => {
            setIsCancelModal(false);
          }}
          handleClose={() => {
            setIsCancelModal(false);
          }}
        />
      )}
    </>
  );
};

export default FAQOrderManagementModal;

export const ModalHeader = styled.div`
  padding: 33px 32px 19px;
  position: relative;
  border-bottom: 1px solid ${colors.GRAY10};
`;

export const HeaderTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_2};
`;

export const CloseIconWrap = styled.span`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const ModalContents = styled.div`
  padding: 24px 32px;
  min-height: 600px;
  display: flex;
`;

export const ContentsSideTitle = styled.div`
  flex: 1;
  max-width: 143px;
  ${typo.HEADING_6};
  color: ${colors.GRAY2};
`;

export const ContentTitle = styled.p`
  padding-left: 60px;
  color: ${colors.GRAY6};
  ${typo.HEADING_9};
  margin-bottom: 16px;
`;

export const ContentsDataWrap = styled.ul`
  min-height: 300px;
  max-height: 41vh;
  overflow: auto;
`;

export const ContentItemWrap = styled.li<{ isDragging?: boolean }>`
  display: flex;
  gap: 28px;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${colors.GRAY9};
  background: ${colors.WHITE};
  cursor: pointer;
  color: ${colors.GRAY2};
  margin-bottom: 12px;
  transition: 0.3s;

  /* item 드래그시 style */

  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        box-shadow: 0px 8px 32px rgba(53, 58, 63, 0.1);
      `;
    }
  }}
  .itemNumber {
    ${typo.HEADING_7};
  }

  .itemContent {
    ${typo.BODY_7};
  }
`;

export const FooterButtonWrap = styled.div``;

const ModalFooter = styled.div`
  > div {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 0 32px 24px;
  }
`;

const ModalContentWrap = styled.div`
  padding: 24px 32px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
`;

const ContentWrap = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const CategoryBox = styled.div`
  /*  */

  background: ${colors.GRAY11};
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 20px;
  border-radius: 12px;
  border: 1px solid ${colors.GRAY10};

  > div {
    flex: 1;
    display: flex;
    gap: 16px;

    > span {
      width: fit-content;
      min-width: 45px;
      display: flex;
      align-items: center;
      ${typo.HEADING_6}
    }
  }
`;
