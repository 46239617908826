import { UploadSize } from "@components/atoms/Upload";
import { CommonElementType } from "@utils/DynamicComponentUtils";
import {
  CommonContentsRenderType,
  CommonCreateBodyKeyType,
  CommonDataType,
} from "src/types/common";

export type Category = "popup" | "banner";

export type ContentCategory = "notice" | "event" | "terms" | "url";

export interface BannerCreateInitValuesType {
  category: Category;
  postStartAt: string;
  postEndAt: string;
  isExposed: boolean;
  title: string;
  thumbnail: string;
  contentCategory: ContentCategory;
  contentId: number | null;
  url: string;
  displayOrder: number;
  contentPostStartAt?: string;
  contentPostEndAt?: string;
  bannerExposureLocation: string[];
  contentTitle?: string | null;
}

export interface BannerCommonContentsOptionType
  extends CommonContentsRenderType {
  placeholder?: string;
  secondPlaceholder?: string;
  secondName?: string;
  isSizeQuestion?: boolean;
  size?: UploadSize;
  hasKeywordToShowContent?: string;
}

export const BannerCreateData: CommonDataType<
  CommonCreateBodyKeyType,
  BannerCreateInitValuesType,
  BannerCommonContentsOptionType
> = {
  createBodyKey: [
    "category",
    "postStartAt",
    "postEndAt",
    "isExposed",
    "title",
    "thumbnail",
    "contentCategory",
    "contentId",
    "url",
    "displayOrder",
    "bannerExposureLocation",
  ],
  formInitialValues: {
    category: "popup",
    postStartAt: "",
    postEndAt: "",
    isExposed: false,
    title: "",
    thumbnail: "",
    contentCategory: "notice",
    contentId: null,
    url: "",
    displayOrder: 0,
    contentPostStartAt: "",
    contentPostEndAt: "",
    bannerExposureLocation: [],
  },
  contentsOption: [
    {
      label: "배너종류",
      name: "category",
      type: CommonElementType.RADIO,
      options: [
        { label: "토스트 팝업", value: "popup" },
        { label: "띠 배너", value: "banner" },
      ],
    },
    {
      label: "분류",
      name: "bannerExposureLocation",
      type: CommonElementType.CHECK_BOX,
      hasKeywordToShowContent: "banner",
      options: [
        {
          label: "전체",
          value: "ENTIRE",
        },
        {
          label: "홈",
          value: "HOME",
        },
        {
          label: "포인트 충전하기",
          value: "PREPAID",
        },
        {
          label: "충전권 선물하기",
          value: "GIFT",
        },
        {
          label: "토크ON",
          value: "TALK_ON",
        },
      ],
    },
    {
      label: "게시 기간",
      name: "postStartAt",
      secondName: "postEndAt",
      type: CommonElementType.DATE_RANGE_PICKER,
      placeholder: "날짜를 입력해주세요",
      secondPlaceholder: "날짜를 입력해주세요",
    },
    {
      label: "노출 상태",
      name: "isExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "제목(관리자용)",
      name: "title",
      type: CommonElementType.INPUT,
      placeholder: "팝업 제목을 입력해주세요",
    },
    {
      label: "팝업 이미지",
      name: "thumbnail",
      type: CommonElementType.IMAGE_UPLOAD,
      size: "large",
      isSizeQuestion: true,
    },
  ],
};
