import { colors, typo } from "@styles/index";
import { Input as AntdInput } from "antd";
import React from "react";
import styled, { CSSObject } from "styled-components";

interface InputProps {
  id?: string;
  type?: "text" | "password" | "number";
  inputType?: "create" | "filter" | "detailInfo";
  full?: boolean;
  label?: string;
  placeholder?: string;
  style?: CSSObject;
  name?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;

  [key: string]: any;
}

/**
 * @param id string;
 * @param label string;
 * @param placeholder string;
 * @param type "text" | "password" | "number" ;
 * @param style CSSObject;
 */
const Input: React.FC<InputProps> = ({
  placeholder,
  type = "text",
  style,
  id,
  value,
  inputType = "filter",
  name,
  disabled,
  onChange,
  onKeyDown,
}) => {
  if (inputType === "create") {
    return (
      <CreateInput
        type={type}
        id={id}
        name={name}
        style={style}
        value={value || ""}
        placeholder={placeholder || "placeholder"}
        allowClear
        onChange={onChange}
        disabled={disabled}
      />
    );
  }
  if (inputType === "filter") {
    return (
      <FilterInput
        type={type}
        id={id}
        name={name}
        style={style}
        value={value || ""}
        placeholder={placeholder || "placeholder"}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
    );
  }

  if (inputType === "detailInfo") {
    return (
      <InfoInput id={id} style={style}>
        {value}
      </InfoInput>
    );
  }

  return null;
};

export default Input;

const CreateInput = styled(AntdInput)`
  width: 100%;
  max-height: 48px;
  border-radius: 12px;
  padding: 12px;
  ${typo.BODY_7};
  /* caret-color: ${colors.PRIMARY};  // cursor color */

  .ant-input-clear-icon {
    display: flex;
    align-items: center;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const FilterInput = styled.input`
  width: 100%;
  border: none;
  padding: 12px;
  color: ${colors.GRAY2};
  ${typo.BODY_8}
  flex: 1;

  ::placeholder {
    color: ${colors.GRAY6};
  }

  :focus {
    outline: none;
  }
`;

const InfoInput = styled.p`
  width: 100%;
  border: none;
  padding: 12px;
  color: ${colors.GRAY2};
  ${typo.BODY_8}
  flex: 1;
`;
