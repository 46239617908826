import useAuthStore from "@store/auth";
import useGlobalModalStore from "@store/modal";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ErrorResponseType, RequestParams } from "./types";

const HOST_URL = process.env.REACT_APP_API_URL;

const LOGIN_URL =
  process.env.REACT_APP_LOGIN_URL ||
  "https://login.everon.co.kr:8443/realms/everon/protocol/openid-connect/auth?response_type=code&client_id=everon-contents-stg";

export const onSessionTokenReset = () => {
  useAuthStore.setState({ userInfo: undefined });
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  axios.defaults.headers.common.Authorization = ``;
  axios.defaults.headers.common["x-refresh"] = ``;
  useGlobalModalStore.getState().hideGlobalModal();

  window.location.href = LOGIN_URL;
};

export const tokenError = () => {
  return useGlobalModalStore.getState().setGlobalModal({
    modalType: "OneButtonModal",
    modalProps: {
      title: "세션이 만료되었습니다.\n다시 로그인 해주세요.",
      onConfirm: () => {
        onSessionTokenReset();
      },
      handleClose: () => {
        onSessionTokenReset();
      },
    },
  });
};

export const unAuthorizedError = () => {
  return useGlobalModalStore.getState().setGlobalModal({
    modalType: "OneButtonModal",
    modalProps: {
      title: "권한이 없습니다.",
      onConfirm: () => {
        onSessionTokenReset();
      },
      handleClose: () => {
        onSessionTokenReset();
      },
    },
  });
};

export const serverError = () => {
  return useGlobalModalStore.getState().setGlobalModal({
    modalType: "OneButtonModal",
    modalProps: {
      title: "서버에러가 발생했습니다.\n잠시 후 다시 시도해주세요.",
      onConfirm: () => {
        onSessionTokenReset();
      },
      handleClose: () => {
        onSessionTokenReset();
      },
    },
  });
};

const request = async <R, T>({
  method,
  url,
  requestBody,
  requestParams,
  isMultipart,
  baseURL,
}: RequestParams<R>): Promise<AxiosResponse<T>> => {
  const axiosInstance = axios.create({
    baseURL: baseURL || HOST_URL,
    headers: {
      Authorization: "",
      "x-refresh": "",
    },
  });

  let headers = {};

  if (isMultipart) {
    headers = {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    };
  }

  // 헤더 설정
  const config: AxiosRequestConfig = {
    headers,
    params: requestParams,
  };

  // REQUEST Interceptor
  axiosInstance.interceptors.request.use((interceptorsConfig) => {
    const { headers } = interceptorsConfig;

    const token = localStorage.getItem("token");
    const refresh = localStorage.getItem("refreshToken");

    if (token || refresh) {
      headers.Authorization = token;
      headers["x-refresh"] = refresh;
    }
    return interceptorsConfig;
  });

  // RESPONSE Interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      const { headers } = response;

      const token = headers.authorization;
      const refresh = headers["x-refresh"];

      if (token || refresh) {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refresh);
      }

      return response;
    },
    async (error: AxiosError) => {
      if (!axios.isAxiosError(error)) {
        console.error("1️⃣ [AXIOS_ERROR]NETWORK : >>>  NET WORK ERROR", error);
        return Promise.reject(error);
      } else {
        const { response } = error;

        const { data } = response as AxiosResponse<any, any>;

        const commonError: ErrorResponseType = {
          errorCode: data?.errorCode || data?.code,
          message: data.message,
          statusCode: data?.statusCode,
          isEveronHttpError: data?.isEveronHttpError || false,
        };

        if (
          commonError?.statusCode === 400 &&
          (commonError?.message.includes("Token is not active") ||
            commonError?.message.includes(
              "Refresh toked issued before the client session started",
            ) ||
            commonError?.message.includes(
              "Invalid refresh token. Token client and authorized client don't match",
            ))
        ) {
          tokenError();
        } else if (
          commonError?.statusCode === 401 &&
          commonError?.message === "Unauthorized"
        ) {
          unAuthorizedError();
        } else if (
          commonError?.statusCode === 502 &&
          commonError?.message === undefined &&
          commonError.isEveronHttpError === true
        ) {
          // error page로 이동 하기
          serverError();
        } else {
          console.error("2️⃣ [500]NETWORK : >>>  NET WORK ERROR", error);
          return Promise.reject(commonError);
        }
      }
    },
  );

  switch (method) {
    case "get":
      return axiosInstance.get(url, config);
    case "post":
      return axiosInstance.post(url, requestBody, config);
    case "put":
      return axiosInstance.put(url, requestBody, config);
    case "delete":
      return axiosInstance.delete(url, {
        data: requestBody,
      });
    default:
      return Promise.reject(new Error("Invalid HttpMethod"));
  }
};

export default request;
