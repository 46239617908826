import { UpdateHistory } from "@apis/faq/types";
import {
  Category,
  NoticeCommonContentsOptionType,
} from "@pages/NoticeCreate/Data";
import { CommonElementType } from "@utils/DynamicComponentUtils";
import { CommonCreateBodyKeyType, CommonDataType } from "src/types/common";

export interface NoticeDetailInitValuesType {
  category: Category;
  title: string;
  isExposed: boolean;
  isPriorityExposed: boolean;
  postStartAt: string;
  postEndAt: string;
  contentCategory: string;
  content: string;
  updateReason: string;
  updateHistory: UpdateHistory[];
}

export const NoticeDetailData: CommonDataType<
  CommonCreateBodyKeyType,
  NoticeDetailInitValuesType,
  NoticeCommonContentsOptionType
> = {
  createBodyKey: [
    "category",
    "title",
    "isExposed",
    "isPriorityExposed",
    "postStartAt",
    "postEndAt",
    "content",
    "updateReason",
  ],
  formInitialValues: {
    category: "NORMAL",
    title: "",
    isExposed: false,
    isPriorityExposed: false,
    postStartAt: "",
    postEndAt: "",
    contentCategory: "NORMAL",
    content: "",
    updateReason: "",
    updateHistory: [],
  },
  contentsOption: [
    {
      label: "게시 유형",
      name: "category",
      type: CommonElementType.RADIO,
      options: [
        { label: "공지사항(충전서비스)", value: "NORMAL" },
        { label: "투자정보", value: "IR" },
      ],
    },
    {
      label: "게시 기간",
      type: CommonElementType.DATE_RANGE_PICKER,
      name: "postStartAt",
      secondName: "postEndAt",
      placeholder: "날짜를 입력해주세요",
      secondPlaceholder: "날짜를 입력해주세요",
    },
    {
      label: "노출 상태",
      name: "isExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "상단 고정 여부",
      name: "isPriorityExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "제목",
      name: "title",
      type: CommonElementType.INPUT,
      placeholder: "제목을 입력해주세요",
    },
  ],
};
