import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import IconBox from "@components/atoms/IconBox";
import { colors, typo } from "@styles/index";
import { RenderSearchFormByType } from "@utils/DynamicComponentUtils";
import { FormikContextType, FormikValues } from "formik";
import styled from "styled-components";

interface FilterProps<T> {
  girdColumn?: number;
  filterData?: any[];
  formInitialValues?: any;
  formik?: FormikContextType<T>;
  handleFormReset?: () => void;
}

const Filter = <T extends FormikValues>({
  girdColumn,
  filterData,
  formInitialValues,
  formik,
  handleFormReset,
}: FilterProps<T>) => {
  return (
    <>
      <FilterBox girdColumn={girdColumn}>
        {filterData &&
          filterData.map(({ name, label, className, ...res }) => {
            return (
              <InputWrap key={label} className={className}>
                <Label htmlFor={name}>{label}</Label>
                {RenderSearchFormByType({ formik, name, ...res })}
              </InputWrap>
            );
          })}
      </FilterBox>

      <ButtonsWrap>
        <ResetButton
          onClick={() => {
            if (handleFormReset) handleFormReset();
            formik?.setValues(formInitialValues);
          }}
        >
          초기화
          <IconBox
            src={ICONS.TEXT_ICON.RESET}
            width={16}
            height={16}
            isCursorPointer
          />
        </ResetButton>

        <Button
          buttonSize="small"
          buttonType="line_primary"
          text={"조회하기"}
          onClick={formik?.handleSubmit}
        />
      </ButtonsWrap>
    </>
  );
};

export default Filter;

export const FilterBox = styled.div<{ girdColumn?: number }>`
  width: 100%;
  margin-bottom: 12px;
  border-top: 1px solid ${colors.GRAY9};

  display: grid;
  grid-template-columns: repeat(${({ girdColumn }) => girdColumn}, 1fr);

  .faqTitle {
    grid-column: 1 / 3;
  }
`;

export const InputWrap = styled.div<{ flex?: number; minWidth?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.GRAY9};
`;

export const Label = styled.label`
  min-width: 130px;
  padding: 12px;
  background-color: ${colors.GRAY11};
  color: ${colors.GRAY4};
  ${typo.BODY_8};
`;

export const ButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  position: relative;
`;

export const ResetButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  ${typo.BODY_9}
  color: ${colors.PRIMARY};
`;
