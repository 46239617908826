import { requestFetchList } from "@apis/common";
import { ErrorResponseType } from "@network/types";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import useRouter from "./useRouter";
import useGlobalModal from "./useGlobalModal";

interface UseFetchList<R> {
  url: string;
  queryKey: string;
  formInitialValues: R;
}

/**
 * @description 리스트 조회 hook
 * @TypeParam T 리스트 데이터 타입
 * @TypeParam R 검색 필터 타입
 * @param url 리스트 조회 api url
 * @param queryKey react-query key
 * @param formInitialValues 검색 필터 초기값
 */
export const useFetchList = <T, R extends {}>({
  formInitialValues,
  url,
  queryKey,
}: UseFetchList<R>) => {
  const { push } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showAlertModal } = useGlobalModal();

  const searchPage = JSON.parse(searchParams.get("page") as string);
  const searchPageSize = JSON.parse(searchParams.get("pageSize") as string);

  const [page, setPage] = useState<number>(searchPage || 1);
  const [pageSize, setPageSize] = useState<number>(searchPageSize || 10);

  const [listData, setListData] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  // PARAMS PAGE
  useEffect(() => {
    searchParams.set("page", String(page));
    searchParams.set("pageSize", String(pageSize));

    const jsonObj: any = formInitialValues;
    const keys = Object.keys(jsonObj);

    for (const key of keys) {
      if (jsonObj[key]) {
        searchParams.set(key, String(jsonObj[key]));
      }
    }

    setSearchParams(searchParams);
  }, [page, pageSize, searchParams, setSearchParams]);

  const searchFilterParams = searchParams.toString();

  // DATA FETCH
  useQuery(
    [queryKey, searchFilterParams],
    () => requestFetchList<T>(url, searchFilterParams),
    {
      onSuccess: (data) => {
        setListData(data.rows);
        setTotalCount(data.count);
      },
      onError: (error: ErrorResponseType) => {
        if (error.statusCode === 404) {
          setListData([]);
          setTotalCount(0);
        }
        if (error.statusCode >= 500) {
          showAlertModal({
            title: error.message ?? "[EV] 서버에서 오류가 발생했어요.",
          });
        }
        console.error("FETCH ERROR 🚀 : >>>  NET WORK ERROR", error);
      },
      enabled: searchFilterParams.includes("page"),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  // FORMIK
  const formik = useFormik<R>({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      onFilterSubmit(values);
    },
  });

  // 검색 필터
  const onFilterSubmit = (values: R) => {
    const filteredData: any = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(values).filter(([_, value]: [any, any]) => {
        return !!value || value.toString() === false.toString();
      }),
    );

    setSearchParams(filteredData);
  };

  // 검색 필터 초기화
  const handleFormReset = () => {
    setSearchParams({});
    setPage(1);
    setPageSize(10);
  };

  // 테이블 사이즈 변경
  const handleChangePageSize = (page: number, pageSize: number) => {
    if (setPage === undefined || setPageSize === undefined) return;
    setPage(page);
    setPageSize(pageSize);
  };

  return {
    push,
    page,
    setPage,
    pageSize,
    totalCount,
    listData,
    formik,
    handleFormReset,
    handleChangePageSize,
  };
};
