import React, { ReactNode } from "react";
import styled from "styled-components";
import Header from "./Header";
import SideMenuBar from "./SideMenuBar";

interface LayoutProps {
  children: ReactNode;
  hasAside?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, hasAside = false }) => {
  return (
    <StyledLayout isOverflowHidden={hasAside}>
      <Header />

      <div className="ContentsWrap">
        {hasAside && <SideMenuBar className="SideMenu" />}
        <main>{children}</main>
      </div>
    </StyledLayout>
  );
};

export default Layout;

const StyledLayout = styled.div<{ isOverflowHidden: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ContentsWrap {
    display: flex;
    flex: 1;

    overflow: ${({ isOverflowHidden }) =>
      isOverflowHidden ? "hidden" : "auto"};
    /* 
                                                                                                                    .SideMenu {
                                                                                                                      flex-shrink: 0;
                                                                                                                    } */

    main {
      flex: 1;
      overflow: ${({ isOverflowHidden }) =>
        isOverflowHidden ? "hidden" : "auto"};
    }
  }
`;
