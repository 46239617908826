import { ICONS } from "@assets/icons";
import { colors, typo } from "@styles/index";
import { Select } from "antd";
import React from "react";
import { CommonOptionType } from "src/types/common";
import styled, { CSSObject } from "styled-components";

interface AntCustomSelectProps {
  style?: CSSObject;
  options?: CommonOptionType[];
  defaultValue?: string;
  onChange?: (value: string | unknown) => void;
  type: "filter" | "create";
  placeholder?: string;
  value?: string;
  disabled?: boolean;
}

const AntCustomSelect: React.FC<AntCustomSelectProps> = ({
  options,
  defaultValue,
  style,
  type = "filter",
  placeholder,
  value,
  disabled = false,
  onChange,
}) => {
  if (type === "filter") {
    return (
      <FilterSelect
        style={style}
        options={options}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder ? placeholder : "placeholder"}
        notFoundContent={<>상위 카테고리를 선택해주세요</>}
      />
    );
  }
  if (type === "create") {
    return (
      <CreateSelect
        style={style}
        options={options}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder ? placeholder : "placeholder"}
        notFoundContent={<>상위 카테고리를 선택해주세요</>}
      />
    );
  }
  return null;
};

export default AntCustomSelect;

const CreateSelect = styled(Select)`
  height: 48px;
  width: 100%;
  ${typo.BODY_7};

  .ant-select-selector {
    height: 100% !important;
    border-radius: 12px !important;
    padding: 12px !important;
  }

  .ant-select-selection-item {
    padding-right: 25px !important;
    line-height: 23px !important;
  }

  .ant-select-arrow {
    width: 24px;
    height: 24px;
    margin-top: 2px;
    top: 25%;
    right: 15px;
    background: no-repeat center url(${ICONS.ARROW_ICON.FILTER_DOWN}) !important;

    span {
      display: none;
    }
  }

  input {
    height: 100% !important;
  }
`;

const FilterSelect = styled(Select)`
  width: 100%;
  min-height: 44px;
  color: ${colors.GRAY6};

  .ant-select-selector {
    min-height: 44px !important;
    border: none !important;
    padding: 12px !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled)
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
  }

  .ant-select-arrow {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    top: 25%;
    right: 12px;
    background: no-repeat center/cover url(${ICONS.ARROW_ICON.FILTER_DOWN}) !important;

    span {
      display: none;
    }
  }

  .ant-select-selection-search-input {
    height: 44px !important;
  }

  .ant-select-selection-item {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px !important;
  }
`;
