import { Rows } from "@apis/common/types";
import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import AntCustomSelect from "@components/atoms/FilterSelect/AntCustomSelect";
import IconBox from "@components/atoms/IconBox";
import { StyledRadio } from "@components/atoms/Radio";
import Table from "@components/atoms/Table";
import BaseModal from "@components/molecules/Modal/BaseModal";
import useGlobalModal from "@hooks/useGlobalModal";
import request from "@network/index";
import { BannerCreateInitValuesType } from "@pages/BannerCreate/Data";
import { ModalType } from "@store/modal";
import { stringToDateFormatter } from "@utils/string-utils";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Radio } from "antd";
import { FormikContextType } from "formik";
import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import OneButtonModal from "../OneButtonModal";
import {
  CloseIconWrap,
  ContentTitle,
  FooterButtonWrap,
  HeaderTitle,
  ModalContents,
  ModalFooter,
  ModalHeader,
} from "../OrderManagementModal/style";
import { ColumnDefs, columnDefs } from "./categoryTabelColumnDefs";
import { BannerListResponse } from "@apis/banner/types";
import { requestConnectTermsHistory } from "@apis/banner";
import { connectionCategoryChange } from "@utils/connection-category-util";

export interface ConnectionModalProps {
  category: string | "notice" | "events" | "privacy-policy";
  modalType?: ModalType;
  modalHeaderTitle?: string;
  contentTitle?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  tableColumnDefs?: ColDef[];
  onClickFirstButton?: () => void;
  onClickSecondButton?: () => void;
  handleClose?: () => void;
  formik: FormikContextType<BannerCreateInitValuesType>;
}

const ConnectionModal: React.FC<ConnectionModalProps> = ({
  category,
  modalType = "ConnectionModal",
  modalHeaderTitle,
  contentTitle,
  firstButtonText,
  secondButtonText,
  handleClose,
  formik,
}) => {
  const { hideModal } = useGlobalModal();

  const { data } = useQuery(
    ["connectionModal", category],
    () => requestConnectionList(category as string),
    {
      enabled: !!category,
    },
  );

  const requestConnectionList = async (category: string) => {
    const { data } = await request<void, Rows<any>>({
      method: "get",
      url: `/${category}?isExposed=true&pageSize=1000`,
    });

    return data.rows;
  };

  const [selectData, setSelectData] = useState<
    BannerListResponse | undefined
  >();

  const CustomCheckBox = ({ data }: ICellRendererParams) => {
    const onSelectedData = () => {
      if (!formik?.values) return;
      const { postStartAt, postEndAt } = formik.values;

      if (!postEndAt) {
        setIsMissingModal(true);
      }

      const postStartDate = new Date(postStartAt);
      const contentsPostStartDate = new Date(data.postStartAt.split("T")[0]);
      const postEndDate = new Date(postEndAt);
      const contentsPostEndDate = new Date(data.postEndAt.split("T")[0]);
      const isPostStartAt = postStartDate >= contentsPostStartDate;
      const isPostEndAt = postEndDate <= contentsPostEndDate;

      console.log(isPostStartAt);
      console.log(isPostEndAt);

      data.postStartAt = data.postStartAt.split("T")[0];
      data.postEndAt = data.postEndAt.split("T")[0];

      if (!isPostStartAt || !isPostEndAt) {
        console.log("등록 할 수 없는 상황");
        setIsMismatchModal(true);
      } else if (isPostStartAt && isPostEndAt) {
        console.log("등록 할 수 있는 상황");
        setSelectData(data);
      }
    };

    return (
      <Radio.Group
        onChange={onSelectedData}
        value={selectData?.id}
        defaultValue={selectData ? selectData.id : ""}
      >
        <StyledRadio key={data.id} value={data.id} />
      </Radio.Group>
    );
  };

  type ColumnDefsKeys = keyof ColumnDefs;
  const column = (category: ColumnDefsKeys): ColDef[] =>
    columnDefs[category].map((col) => {
      if (col.field === "isSelect") {
        col.cellRenderer = CustomCheckBox;
      }
      return col;
    });

  interface TermsCategory {
    value: string;
    label: string;
  }

  const [termsCategory, setTermsCategory] = useState<TermsCategory | undefined>(
    undefined,
  );

  const { data: termsCategoryData } = useQuery(
    ["termsDetail", termsCategory?.value],
    () => requestConnectTermsHistory(termsCategory?.value),
    {
      enabled: !!termsCategory?.value,
    },
  );

  // 약관 카테고리 옵션
  const options: TermsCategory[] =
    data?.map((item) => {
      return {
        label: item.title,
        value: item.category,
      };
    }) || [];

  // table data custom - 게시 기간 추가
  const cusTomData = data?.map((item) => {
    return {
      ...item,
      publishDuration: `${stringToDateFormatter(
        item.postStartAt,
      )} ~ ${stringToDateFormatter(item.postEndAt)}`,
    };
  });

  const connectionData = () => {
    if (category === "terms") {
      return termsCategoryData?.termHistories.filter(
        ({ isExposed }) => isExposed === true,
      );
    } else {
      return cusTomData;
    }
  };

  const termsSelectRenderer = () => {
    const onChange = (value: unknown) => {
      const selectedValue = options?.find((option) => option.value === value);
      setTermsCategory(selectedValue);
    };

    return (
      <>
        <StyledContentTitle>약관</StyledContentTitle>
        <div style={{ marginBottom: "24px" }}>
          <AntCustomSelect
            type="create"
            options={options}
            value={termsCategory?.value}
            onChange={onChange}
            defaultValue={"약관을 선택해주세요"}
          />
        </div>
      </>
    );
  };

  // 게시 기간 일치 하지 않을 때 모달
  const [isMismatchModal, setIsMismatchModal] = useState(false);
  const openPublishPeriodMismatchModal = () => {
    return (
      <OneButtonModal
        title={`게시기간이 올바르지 않아요.\n다시 확인해주세요.`}
        handleClose={() => setIsMismatchModal(false)}
        onConfirm={() => setIsMismatchModal(false)}
      />
    );
  };
  // 게시 종료 기간 누락 모달
  const [isMissingModal, setIsMissingModal] = useState(false);
  const openPublishPeriodMissingModal = () => {
    return (
      <OneButtonModal
        title={`게시기간을 먼저 선택해주세요.`}
        handleClose={() => {
          setIsMissingModal(false);
          hideModal();
        }}
        onConfirm={() => {
          setIsMissingModal(false);
          hideModal();
        }}
      />
    );
  };

  return (
    <>
      <BaseModal
        isOpen
        handleClose={handleClose}
        modalWidth={65}
        disableModalPadding
        modalType={modalType}
      >
        <div>
          <ModalHeader>
            <HeaderTitle>{modalHeaderTitle || "modalHeaderTitle"}</HeaderTitle>
            <CloseIconWrap>
              <IconBox
                src={ICONS.MODAL_ICON.CLOSE}
                width={24}
                height={24}
                isCursorPointer
                onClick={handleClose}
              />
            </CloseIconWrap>
          </ModalHeader>

          <ModalContents>
            <ContentWrap>
              {/* 약관 카테고리 선택 */}
              {category === "terms" && termsSelectRenderer()}

              <StyledContentTitle>
                {contentTitle || "contentTitle"}
              </StyledContentTitle>
              <div style={{ height: category === "terms" ? "80%" : "100%" }}>
                <Table
                  tableContentsInfoData={column(category as ColumnDefsKeys)}
                  rowData={connectionData() || []}
                  setSelectData={setSelectData}
                  formik={formik}
                  setIsMissingModal={setIsMissingModal}
                  setIsMismatchModal={setIsMismatchModal}
                />
              </div>
            </ContentWrap>
          </ModalContents>

          <ModalFooter>
            <FooterButtonWrap>
              <Button
                style={{ marginRight: 8 }}
                text={firstButtonText || "firstButtonText"}
                buttonSize="lager"
                buttonType="text"
                onClick={() => {
                  hideModal();
                }}
              />
              <Button
                text={secondButtonText || "secondButtonText"}
                buttonSize="lager"
                onClick={() => {
                  hideModal();
                  // 약관 카테고리 초기화
                  setTermsCategory(undefined);

                  // ID
                  formik?.setFieldValue("contentId", selectData?.id);

                  // TITLE
                  if (category === "terms") {
                    formik?.setFieldValue("contentTitle", termsCategory?.label);
                  } else {
                    formik?.setFieldValue("contentTitle", selectData?.title);
                  }

                  // CATEGORY
                  if (category)
                    formik?.setFieldValue(
                      "contentCategory",
                      connectionCategoryChange(category),
                    );

                  // 게시 기간
                  formik?.setFieldValue(
                    "contentPostStartAt",
                    selectData?.postStartAt,
                  );
                  formik?.setFieldValue(
                    "contentPostEndAt",
                    selectData?.postEndAt,
                  );
                }}
              />
            </FooterButtonWrap>
          </ModalFooter>
        </div>
      </BaseModal>
      {isMismatchModal && openPublishPeriodMismatchModal()}
      {isMissingModal && openPublishPeriodMissingModal()}
    </>
  );
};

export default ConnectionModal;

const StyledContentTitle = styled(ContentTitle)`
  padding-left: 0;
  margin-bottom: 12px;
`;

const ContentWrap = styled.div`
  width: 100%;
  height: 600px;
`;
