import { FaqListResponse } from "@apis/faq/types";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import Table from "@components/atoms/Table";
import ExcelButton from "@components/molecules/ExcelButton";
import Filter from "@components/molecules/Filter";
import { ApiUrls } from "@constants/api-urls";
import { PrivateRoutes } from "@constants/urls";
import { useFetchFaqCategoryOption } from "@hooks/useFetchFaqCategoryOption";
import { useFetchList } from "@hooks/useFetchList";
import useGlobalModal from "@hooks/useGlobalModal";
import { colors, typo } from "@styles/index";
import { FilterElementType } from "@utils/DynamicComponentUtils";
import { excelDownload } from "@utils/excel-download";
import { Pagination } from "antd";
import { htmlToText } from "html-to-text";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { faqData, FaqFormType, FaqInitialValues } from "./Data";

const FaqManagement: React.FC = () => {
  // Data
  const filter = faqData.forms;
  const formInitialValues = faqData.formInitialValues;
  const tableContentsInfoData = faqData.tableList;

  // Props
  const MAIN_TITLE = "FAQ 관리";
  const TABLE_TITLE = "FAQ 목록";
  const CREATE_BUTTON_TEXT = "FAQ 등록";
  const FILTER_GIRD_COLUMN = 3;
  const INIT_FAQ_CATEGORY_CODE = "NOTF00";

  const isCreateButton = true;
  const isExcelButton = true;

  // Hook Props
  const QUERY_KEY = "faqList";
  const URL = ApiUrls.faq;
  const DETAIL_PATH = PrivateRoutes.FaqDetail;
  const CREATE_PATH = PrivateRoutes.FaqCreate;

  const {
    push,
    listData,
    formik,
    page,
    setPage,
    pageSize,
    totalCount,
    handleChangePageSize,
    handleFormReset,
  } = useFetchList<FaqListResponse, FaqInitialValues>({
    url: URL,
    queryKey: QUERY_KEY,
    formInitialValues: formInitialValues,
  });
  const { showFaqOrderManagementModal } = useGlobalModal();

  // PROPS

  /** 작성 페이지 이동 */
  const onCreateClick = () => {
    push(CREATE_PATH);
  };

  /** 상세 페이지 이동 id */
  const onDetailClick = (id: number | string) => {
    push(`${DETAIL_PATH}/${id}`);
  };

  const selectedCategory1 = useRef("");
  const selectedCategory2 = useRef("");

  // FAQ 카테고리 조회
  const { categoryOption: categoryCustomOption1 } = useFetchFaqCategoryOption(
    INIT_FAQ_CATEGORY_CODE,
  );
  const {
    categoryOption: categoryCustomOption2,
    setCategoryOption: setCategoryCustomOption2,
  } = useFetchFaqCategoryOption(formik.values.category1);
  const {
    categoryOption: categoryCustomOption3,
    setCategoryOption: setCategoryCustomOption3,
  } = useFetchFaqCategoryOption(formik.values.category2);

  const faqFilterData: FaqFormType[] = [
    {
      type: FilterElementType.SELECT,
      name: "category1",
      label: "대분류",
      option: categoryCustomOption1,
    },
    {
      type: FilterElementType.SELECT,
      name: "category2",
      label: "중분류",
      option: categoryCustomOption2,
    },
    {
      type: FilterElementType.SELECT,
      name: "category3",
      label: "소분류",
      option: categoryCustomOption3,
    },
    ...filter,
  ];

  const handleCategoryChange = (categoryCode: string) => {
    if (selectedCategory1.current !== categoryCode) {
      selectedCategory1.current = categoryCode;
      selectedCategory2.current = "";
      formik.setValues({
        ...formik.values,
        category2: "",
        category3: "",
      });
    }
  };

  const handleSubCategoryChange = (categoryCode: string) => {
    if (selectedCategory2.current !== categoryCode) {
      selectedCategory2.current = categoryCode;
      formik.setFieldValue("category3", "");
    }
  };

  const faqCategoryOrderChangeClick = () => {
    showFaqOrderManagementModal({
      title: "전체 탭 순번 관리",
      type: "sortedCategories",
    });
  };

  /** 데이터 업데이트를 하지 않았을 경우 createdAt과 updatedAt이 같은 값이 나오기 때문에 createAt으로 통일 */
  const faqTableListData = listData?.map((data) => ({
    ...data,
    updatedAt: data.updatedAt ?? data.createdAt,
  }));

  const onExcelDownload = () => {
    const header = [
      "노출 상태",
      "대분류",
      "중분류",
      "소분류",
      "제목",
      "내용",
      "업데이트 시간",
      "작성자",
    ];

    const colsWidth = [50, 100, 100, 100, 400, 600, 180, 100];

    const excelData = faqTableListData?.map((data) => {
      return {
        isExposed: data.isExposed ? "노출" : "비노출",
        category1: data.category1,
        category2: data.category2,
        category3: data.category3,
        title: data.title,
        content: htmlToText(data.content),
        updatedAt: data.updatedAt
          ? moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss")
          : moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        manger: data.manager ?? "-",
      };
    });

    if (excelData)
      excelDownload({
        fileName: "FAQ 관리",
        header,
        data: excelData,
        colsWidth,
      });
  };

  /** 상위의 카테고리 변경시 하위 카테고리 초기화 */
  useEffect(() => {
    const { category1 } = formik.values;
    if (!category1) {
      setCategoryCustomOption2([]);
      setCategoryCustomOption3([]);
    } else {
      handleCategoryChange(category1);
    }
    formik.setFieldValue("category2", "");
    formik.setFieldValue("category3", "");
  }, [formik.values.category1]);

  useEffect(() => {
    const { category2 } = formik.values;
    if (!category2) {
      setCategoryCustomOption3([]);
    } else {
      handleSubCategoryChange(category2);
    }
    formik.setFieldValue("category3", "");
  }, [formik.values.category2]);

  return (
    <ContentsWrap>
      <MainHeaderTitle>{MAIN_TITLE}</MainHeaderTitle>

      <ContentBox>
        <Filter
          formik={formik}
          filterData={faqFilterData}
          girdColumn={FILTER_GIRD_COLUMN}
          formInitialValues={formInitialValues}
          handleFormReset={handleFormReset}
        />
      </ContentBox>

      <ContentBoxWithHeader>
        <TableHeader>
          <TableTitle>{TABLE_TITLE}</TableTitle>
          <HeaderButtonWrap>
            {isExcelButton && (
              <ExcelButton
                onClick={() => {
                  onExcelDownload();
                }}
              />
            )}

            <Button
              buttonSize="small"
              buttonType="line_black"
              text="질문별 순번 관리"
              onClick={faqCategoryOrderChangeClick}
            />
            {isCreateButton && (
              <Button
                buttonSize="small"
                text={CREATE_BUTTON_TEXT || "tableButtonText"}
                onClick={onCreateClick}
              />
            )}
          </HeaderButtonWrap>
        </TableHeader>

        <Table<FaqListResponse>
          rowData={faqTableListData}
          tableContentsInfoData={tableContentsInfoData}
          currentPageSize={pageSize}
          onDetailClick={onDetailClick}
        />

        {/* pageNation */}
        <TableBottomWrap>
          <ContentsTotal>
            총<span>{totalCount}</span>
          </ContentsTotal>
          <PageNationWrap>
            <Pagination
              defaultCurrent={page}
              defaultPageSize={pageSize}
              total={totalCount}
              showSizeChanger
              locale={{ items_per_page: "개씩 보기" }}
              current={page}
              pageSize={pageSize}
              onShowSizeChange={handleChangePageSize}
              onChange={setPage}
            />
          </PageNationWrap>
        </TableBottomWrap>
      </ContentBoxWithHeader>
    </ContentsWrap>
  );
};

export default FaqManagement;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

/** 두번째 컨테이너 스타일 */
const ContentBoxWithHeader = styled.div`
  margin-top: 20px;
  padding: 22px 20px;
  background: ${colors.WHITE};
  border-radius: 16px;
  border: 1px solid ${colors.GRAY9};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderButtonWrap = styled.div`
  display: flex;
  max-height: 32px;
  gap: 8px;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const TableBottomWrap = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ContentsTotal = styled.span`
  display: flex;
  gap: 8px;
  color: ${colors.GRAY6};
  font-weight: 500;
  ${typo.BODY_8};

  span {
    ${typo.BODY_8};
    font-weight: 500;
    color: ${colors.GRAY2};
  }
`;

const PageNationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
