import { ICONS } from "@assets/icons";
import useRouter from "@hooks/useRouter";
import { colors, typo } from "@styles/index";
import { Modal } from "antd";
import Upload from "antd/es/upload/Upload";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile, UploadProps } from "antd/lib/upload";
import { FormikContextType } from "formik";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Button from "../Button";
import IconBox from "../IconBox";

export type UploadSize = "small" | "medium" | "large" | "xsmall";

interface CustomUploadProps {
  uploadSize: UploadSize;
  formik?: FormikContextType<any>;
  name?: string;
}

const CustomUpload: React.FC<CustomUploadProps> = ({
  uploadSize = "large",
  formik,
  name,
}) => {
  const { query, pathname } = useRouter();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [thumbnailState, setThumbnailState] = useState({
    thumbnailFirstCheck: false,
    isThumbnail: false,
  });

  useEffect(() => {
    if (
      pathname.includes("detail") &&
      !!formik?.values.thumbnail &&
      !thumbnailState.thumbnailFirstCheck
    ) {
      setThumbnailState({
        thumbnailFirstCheck: true,
        isThumbnail: true,
      });
    }
  }, [formik?.values.thumbnail]);

  // detail일때
  useEffect(() => {
    if (thumbnailState.thumbnailFirstCheck && thumbnailState.isThumbnail) {
      setFileList([
        {
          uid: query.id as string,
          name: formik?.values.thumbnail.split("/")[3],
          url: formik?.values.thumbnail,
        },
      ]);
    }
  }, [thumbnailState]);

  useEffect(() => {
    setFileList([]);
  }, [formik?.values.category]);

  const onCancel = () => setPreviewOpen(false);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1),
    );
  };

  // 이미지 파일 업로드 파일 선택
  const onSelectImage: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);

    if (name) formik?.setFieldValue(name, newFileList[0]);
  };

  // 업로드 버튼
  const uploadButton = (uploadSize: UploadSize) => {
    const isLargeUpload = uploadSize === "large";
    const isXSmallUpload = uploadSize === "xsmall";
    const isSmallUpload = uploadSize === "small";
    const largeUploadNotice = (
      <p>{`확장자 jpg, png 등록 / 권장 사이즈 375*280px\n/ 용량 10MB 이하`}</p>
    );
    const xsmallUploadNotice = `이미지를 드래그하거나\n첨부해주세요`;
    const commonUploadNotice = `이미지를 드래그하거나 첨부해주세요`;

    return (
      <UploadButton uploadSize={uploadSize}>
        {isLargeUpload && (
          <IconBox width={37} height={42} src={ICONS.DATE_ICON.UPLOAD_ICON} />
        )}
        <div>
          {isXSmallUpload ? xsmallUploadNotice : commonUploadNotice}
          {isLargeUpload && largeUploadNotice}
        </div>
        {isSmallUpload || isXSmallUpload ? (
          <Button
            buttonSize="regular"
            buttonType="under_line"
            text="파일첨부"
          />
        ) : (
          <Button buttonType="gray" buttonSize="regular" text="파일첨부" />
        )}
      </UploadButton>
    );
  };

  return (
    <>
      <StyledUpload
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76" // 업로드 할 url
        listType="picture-card"
        fileList={fileList}
        onPreview={onPreview}
        onChange={onSelectImage}
        uploadSize={uploadSize}
      >
        {/* img upload wrap */}
        {fileList.length >= 1 ? null : uploadButton(uploadSize)}
      </StyledUpload>
      <StyledModal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={onCancel}
        uploadSize={uploadSize}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </StyledModal>
    </>
  );
};

export default CustomUpload;

const StyledUpload = styled(Upload)<CustomUploadProps>`
  .ant-upload.ant-upload-select-picture-card {
    transition: 0.25s;
    border: 1px dashed ${colors.GRAY8};
    background: ${colors.WHITE};
    border-radius: 8px;

    ${({ uploadSize }) => {
      switch (uploadSize) {
        case "xsmall": {
          return css`
            width: 150px;
            height: 150px;
          `;
        }
        case "small": {
          return css`
            width: 335px;
            height: 86px;
          `;
        }
        case "medium": {
          return css`
            width: 335px;
            height: 160px;
          `;
        }
        case "large": {
          return css`
            width: 375px;
            height: 280px;
          `;
        }
      }
    }}
    /* background로 dashed 넣기 */
            /* border: none; */
            /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='lightgray' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
        :hover {
      border-color: ${colors.GRAY6};

      /* background로 dashed 넣기 */
      /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='black' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
    }
  }

  .ant-tooltip,
  .ant-tooltip-placement-top,
  .ant-tooltip-hidden,
  .ant-tooltip-inner {
    display: none !important;
  }

  .ant-upload-list-item {
    border: 1px dashed ${colors.GRAY8} !important;
  }

  /* preview image */

  .ant-upload-list-picture-card-container {
    overflow: hidden;

    ${({ uploadSize }) => {
      switch (uploadSize) {
        case "xsmall": {
          return css`
            width: 150px;
            height: 150px;
          `;
        }
        case "small": {
          return css`
            width: 335px;
            height: 86px;
          `;
        }
        case "medium": {
          return css`
            width: 335px;
            height: 160px;
          `;
        }
        case "large": {
          return css`
            width: 375px;
            height: 280px;
          `;
        }
      }
    }}
  }

  /* image uploadWrap style */

  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload-list-picture .ant-upload-list-item {
    padding: 0px;
    border: 1px dashed ${colors.RED};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${({ uploadSize }) => {
      switch (uploadSize) {
        case "small": {
          return css`
            border-radius: 8px;
          `;
        }
        case "medium": {
          return css`
            border-radius: 8px;
          `;
        }
        case "large": {
          return css`
            border-radius: 4px;
          `;
        }
      }
    }};

    /* image upload size */

    .ant-upload-list-item-info {
      width: 100%;
      height: 100%;

      .ant-upload-span {
        img {
          object-fit: cover;
        }
      }
    }
  }

  /* upload loadingBar style */

  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 10px;
    width: calc(100% - 100px);
  }
`;

const UploadButton = styled.div<{ uploadSize: UploadSize }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ uploadSize }) =>
    uploadSize === "small" || uploadSize === "xsmall" ? 4 : 16}px;
  white-space: pre-wrap;

  div {
    ${typo.BODY_9};
    font-weight: 500;
    color: ${colors.GRAY2};
    width: fit-content;

    p {
      color: ${colors.GRAY6};
      white-space: pre-wrap;
    }
  }

  button {
    width: 69px;
    margin: auto;
  }
`;

const StyledModal = styled(Modal)<{ uploadSize: UploadSize }>``;
