import request from "@network/index";
import { Page, Row, Rows, UploadData } from "./types";

/** 조회 */
export const requestFetchList = async <T>(url: string, params: string) => {
  const { data } = await request<void, Rows<T>>({
    method: "get",
    url: `${url}?${params}`,
  });
  return data;
};

/** 상세 조회 */
export const requestFetchDetail = async <T>(
  url: string,
  id?: string | (string | null)[],
) => {
  if (!id) return;
  const { data } = await request<void, Row<T>>({
    method: "get",
    url: `${url}/${id}`,
  });
  return data.row;
};

/** 삭제 */
export const requestDelete = async (
  url: string,
  id: string | (string | null)[],
) => {
  if (!id) return;
  await request<void, void>({
    method: "delete",
    url: `${url}/${id}`,
  });
};

/** 이미지 업로드 */
export const requestImageUpload = async (uploadData: UploadData) => {
  const formData = new FormData();
  formData.append("image", uploadData.imageFile);
  formData.append("category", uploadData.category);
  try {
    const { data } = await request<FormData, Rows<string>>({
      method: "post",
      url: "common/file-upload",
      requestBody: formData,
      isMultipart: true,
    });
    return data.rows[0];
  } catch (e) {
    console.error("IMAGE UPLOAD ERROR 🚀 >>>>> :", e);
  }
};

/** 딥링크 생성 */
export const deepLink = async (page: Page, id: string) => {
  const title = "마음편한 충전서비스 에버온 앱으로 이동해요.";
  const description = "설레는 혜택과 스마트 충전, 에버온 앱 하나로 경험하세요";
  const imageUrl = "https://resource.everon.co.kr/ios_deeplink_preview.png";
  let path = "";

  switch (page) {
    case "notice":
      path = `https://everon.co.kr?linkTo=NoticeDetail&noticeId=${id}`;
      break;
    case "event":
      path = `https://everon.co.kr?linkTo=EventDetail&eventId=${id}`;
      break;

    case "faq":
      path = `https://everon.co.kr?linkTo=FAQ&faqId=${id}`;
      break;

    case "manual":
      path = `https://everon.co.kr?linkTo=ChargerResolutionDetail&chargerId=${id}`;
      break;

    default:
      return "";
  }

  // TODO: 파이어베이스 딥링크 query 생성시 & 넣으면 안되는데 넣었음. (파이어베이스 쿼리스트링이랑 구분안됨) 슬로그업에선 &을 encodeURIComponent로 억지로 구분해서 파싱하는데 개선해야됨.
  // const link = `https://everon.page.link/?link=${encodeUrl}&apn=${process.env.REACT_APP_DEEP_LINK_APN}&isi=${process.env.REACT_APP_DEEP_LINK_ISI}&ibi=${process.env.REACT_APP_DEEP_LINK_IBI}`;

  const encodeUrl = encodeURIComponent(path);
  const metaData = `&apn=${process.env.REACT_APP_DEEP_LINK_APN}&isi=${process.env.REACT_APP_DEEP_LINK_ISI}&ibi=${process.env.REACT_APP_DEEP_LINK_IBI}&st=${title}&sd=${description}&si=${imageUrl}`;
  const link =
    encodeURI(`https://everon.page.link/?link=`) +
    encodeUrl +
    encodeURI(metaData);

  const { data } = await request<{ longUrl: string }, string>({
    method: "post",
    url: "/common/convert-to-short-url",
    requestBody: { longUrl: link },
  });

  return data;
};
