import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import IconBox from "@components/atoms/IconBox";
import Input from "@components/atoms/Input";
import { StyledRadio } from "@components/atoms/Radio";
import BaseModal from "@components/molecules/Modal/BaseModal";
import {
  CloseIconWrap,
  HeaderTitle,
  ModalHeader,
} from "@components/molecules/Modal/OrderManagementModal/style";
import regex from "@constants/regex";
import { colors, typo } from "@styles/index";
import { Radio, RadioChangeEvent } from "antd";
import { FormikContextType } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export interface EventButtonCreateModalProps {
  onCancel?: () => void;
  hideModal: () => void;
  formik: FormikContextType<any>;
}

interface ConnectUrl {
  title: string;
  url: string;
}

const EventButtonCreateModal: React.FC<EventButtonCreateModalProps> = ({
  onCancel,
  hideModal,
  formik,
}) => {
  const [urlConnectionLength, setURLConnectionLength] = useState(1);

  const [eventUrls, setEventUrls] = useState<ConnectUrl[]>([
    { title: "", url: "" },
  ]);

  const options = [
    { label: "1개", value: 1 },
    { label: "2개", value: 2 },
  ];

  const onChangeTitle = (e: React.ChangeEvent<any>, idx: number) => {
    setEventUrls((prev) => {
      return prev.map((urls, index) => {
        if (index === idx) {
          return { ...urls, title: e.target.value };
        }
        return urls;
      });
    });
  };

  const onChangeUrl = (e: React.ChangeEvent<any>, idx: number) => {
    setEventUrls((prev) => {
      return prev.map((urls, index) => {
        if (index === idx) {
          return { ...urls, url: e.target.value };
        }
        return urls;
      });
    });
  };

  const setURLConnectionCount = (e: RadioChangeEvent) => {
    const connectionCount = e.target.value;
    setURLConnectionLength(connectionCount);

    setEventUrls((prev) => {
      const [firstUrl] = prev;
      const { title, url } = firstUrl;
      if (connectionCount === 1) {
        if (!title && !url) {
          return [{ title: "", url: "" }];
        } else {
          return [{ title, url }];
        }
      } else {
        if (title?.length > 7) {
          return [
            { title: title?.slice(0, 7), url },
            { title: "", url: "" },
          ];
        }
        return [...prev, { title: "", url: "" }];
      }
    });
  };

  const saveConnectedURL = () => {
    formik.setFieldValue("urls", eventUrls);
    hideModal();
  };

  const isValidUrlButton = useCallback(() => {
    const isTitle = eventUrls.every((urls) => urls.title);

    const isUrl = eventUrls.every((urls) => {
      const urlRegex = regex.url;
      return urlRegex.test(urls.url);
    });

    return isTitle && isUrl;
  }, [eventUrls]);

  useEffect(() => {
    if (formik.values.urls && formik.values.urls.length > 0) {
      setEventUrls(formik.values.urls);
      setURLConnectionLength(formik.values.urls.length);
    }
  }, [formik.values.urls]);

  return (
    <BaseModal
      isOpen
      modalWidth={35}
      disableModalPadding
      modalType={null}
      handleClose={onCancel}
      contentsStyle={{
        height: "auto",
      }}
    >
      <div>
        <ModalHeader>
          <HeaderTitle>{`버튼 생성하기`}</HeaderTitle>
          <CloseIconWrap>
            <IconBox
              src={ICONS.MODAL_ICON.CLOSE}
              width={24}
              height={24}
              isCursorPointer
              onClick={onCancel}
            />
          </CloseIconWrap>
        </ModalHeader>
        <ModalContentWrap>
          <ConnectUrlSelectWrap>
            <span>연결할 URL</span>
            <Radio.Group
              onChange={setURLConnectionCount}
              defaultValue={options[0].value}
              value={urlConnectionLength}
            >
              {options?.map(({ label, value }) => {
                return (
                  <StyledRadio key={label} value={value}>
                    {label}
                  </StyledRadio>
                );
              })}
            </Radio.Group>
          </ConnectUrlSelectWrap>
          {Array.from({ length: urlConnectionLength }).map((_, idx) => {
            return (
              <UrlCard key={idx}>
                <span>URL0{String(idx + 1)}</span>
                <div>
                  <div>
                    <p className="label">버튼명</p>
                    <Input
                      inputType="create"
                      placeholder="노출명을 입력해주세요."
                      id="title"
                      name="title"
                      onChange={(e) => onChangeTitle(e, idx)}
                      value={eventUrls[idx].title || ""}
                      maxLength={urlConnectionLength === 1 ? 19 : 7}
                    />
                  </div>
                  <div>
                    <p className="label">연결 할 URL</p>
                    <Input
                      inputType="create"
                      placeholder="연결 할 URL을 입력해주세요."
                      id="url"
                      name="url"
                      onChange={(e) => onChangeUrl(e, idx)}
                      value={eventUrls[idx].url || ""}
                    />
                  </div>
                </div>
              </UrlCard>
            );
          })}
        </ModalContentWrap>
        <ModalFooter>
          <div>
            <Button
              buttonSize="lager"
              buttonType="text"
              text="닫기"
              onClick={onCancel}
            />
            <Button
              buttonSize="lager"
              text="연결하기"
              onClick={saveConnectedURL}
              disabled={!isValidUrlButton()}
            />
          </div>
        </ModalFooter>
      </div>
    </BaseModal>
  );
};

export default EventButtonCreateModal;
const ModalFooter = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${colors.GRAY10};

  > div {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 0 32px 24px;
  }
`;
const ModalContentWrap = styled.div`
  padding: 24px 52px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
`;

const ConnectUrlSelectWrap = styled.div`
  background: ${colors.GRAY11};
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 76px;
  border-radius: 12px;
  border: 1px solid ${colors.GRAY10};

  margin-bottom: 24px;

  > span {
    width: 120px;
    ${typo.HEADING_9};
    line-height: 20px;
  }
`;

const UrlCard = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 16px;

  > span {
    ${typo.HEADING_9}
  }

  > div {
    display: flex;
    flex-direction: column;

    gap: 8px;

    > div {
      display: flex;

      > .label {
        display: flex;
        align-items: center;
        max-width: 120px;
        width: 100%;
        ${typo.BODY_9}
      }
    }
  }

  + div {
    margin-top: 20px;

    padding-top: 20px;
    border-top: 1px solid ${colors.GRAY10};
  }
`;
