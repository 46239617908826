import CustomCheckBox, { BasicOptionType } from "@components/atoms/CheckBox";
import {
  InputContentsWrap,
  InputWrap,
  Label,
  LabelInnerWrap,
  LabelWrap,
} from "@pages/BannerCreate";
import { findUncommonElements } from "@utils/data-fns";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { FormikContextType } from "formik";
import React from "react";

interface RenderBannerExposureLocationCheckboxProps {
  formik: FormikContextType<any>;
  name: string;
  checkBoxOptions: BasicOptionType[];
  label: string;
}

const RenderBannerExposureLocationCheckbox: React.FC<
  RenderBannerExposureLocationCheckboxProps
> = ({ label, formik, name, checkBoxOptions }) => {
  const selectItems = formik && name && formik?.values[name];

  return (
    <InputWrap>
      <LabelWrap>
        <LabelInnerWrap>
          <Label>{label}</Label>
        </LabelInnerWrap>
      </LabelWrap>
      <InputContentsWrap>
        <CustomCheckBox
          selectItems={selectItems}
          options={checkBoxOptions}
          onChange={(checkedValues: CheckboxValueType[]) => {
            const prevItems = formik.values[name];
            const currItems = checkedValues;
            const clickedItem = findUncommonElements(prevItems, checkedValues);
            const allItems = ["ENTIRE", "HOME", "PREPAID", "GIFT", "TALK_ON"];

            const clickedEntire = clickedItem[0] === "ENTIRE";
            const hasEntire = prevItems.includes("ENTIRE");
            const hasAllItems =
              checkedValues.includes("HOME") &&
              checkedValues.includes("PREPAID") &&
              checkedValues.includes("GIFT") &&
              checkedValues.includes("TALK_ON");

            let setItems: CheckboxValueType[];

            if (clickedEntire) {
              setItems = hasEntire ? [] : allItems;
            } else {
              setItems = hasAllItems
                ? allItems
                : [...currItems.filter((item) => item !== "ENTIRE")];
            }

            formik?.setFieldValue(name, setItems);
          }}
        />
      </InputContentsWrap>
    </InputWrap>
  );
};

export default RenderBannerExposureLocationCheckbox;
