import { FilterElementType } from "@utils/DynamicComponentUtils";
import {
  bannerKindCheck,
  exposureStatusCheck,
  textFormat,
  upDataAtFormat,
} from "@utils/TableItemFormattingUtils";
import { ColDef } from "ag-grid-community";
import {
  CommonFilterDataType,
  CommonFormType,
  CommonPostDateType,
} from "src/types/common";

export interface BannerInitialValues extends CommonPostDateType {
  title?: string;
  category?: string;
  isExposed?: string;
}

export interface BannerFormType extends CommonFormType {
  secondName?: string;
}

export const bannerData: CommonFilterDataType<
  BannerInitialValues,
  BannerFormType,
  ColDef
> = {
  formInitialValues: {
    title: "",
    category: "",
    isExposed: "",
    startDate: "",
    endDate: "",
  },
  forms: [
    {
      type: FilterElementType.INPUT,
      name: "title",
      label: "제목",
    },
    {
      type: FilterElementType.SELECT,
      name: "category",
      label: "배너 종류",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "토스트 팝업",
          value: "popup",
        },
        {
          label: "띠 배너",
          value: "banner",
        },
      ],
    },
    {
      type: FilterElementType.SELECT,
      name: "isExposed",
      label: "노출 상태",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "노출",
          value: true,
        },
        {
          label: "미노출",
          value: false,
        },
      ],
    },
    {
      type: FilterElementType.DATE_RANGE_PICKER,
      name: "startDate",
      secondName: "endDate",
      label: "게시 기간",
    },
  ],
  tableList: [
    {
      field: "category",
      headerName: "배너 종류",
      width: 100,
      maxWidth: 120,
      flex: 1,
      suppressMovable: true,
      cellRenderer: bannerKindCheck,
    },
    {
      field: "postDataAt",
      headerName: "게시 기간",
      minWidth: 200,
      maxWidth: 360,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "isExposed",
      headerName: "노출 상태",
      width: 100,
      maxWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: exposureStatusCheck,
    },
    {
      field: "title",
      headerName: "제목",
      width: 630,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },

    {
      field: "updatedAt",
      headerName: "업데이트 일시",
      minWidth: 180,
      maxWidth: 240,
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
    {
      field: "manager",
      headerName: "마지막 수정",
      pinned: "right",
      suppressMovable: true,
      maxWidth: 180,
      cellRenderer: textFormat,
    },
  ],
};
