import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import { colors, typo } from "@styles/index";
import React from "react";
import styled, { CSSObject } from "styled-components";

interface ExcelButtonProps {
  className?: string;
  style?: CSSObject;
  iconsWidth?: number;
  iconsHeight?: number;
  onClick?: () => void;
}

const ExcelButton: React.FC<ExcelButtonProps> = ({
  className,
  style,
  iconsWidth = 20,
  iconsHeight = 20,
  onClick,
}) => {
  return (
    <StyledButton
      className={className}
      style={style}
      firstIconSrc={ICONS.BUTTON_ICON.EXCEL}
      buttonSize="small"
      text="엑셀 다운로드"
      firstIconWidth={iconsWidth}
      firstIconHeight={iconsHeight}
      onClick={onClick}
    />
  );
};

export default ExcelButton;

const StyledButton = styled(Button)`
  background: ${colors.GREEN5};
  ${typo.BUTTON4};
  font-weight: 600;
  color: ${colors.GREEN};
  padding: 7px 8px 5px;
  display: flex;
  align-items: center;
  gap: 8px;

  :hover {
    background: ${colors.GREEN5};
    color: ${colors.GREEN};
  }

  :active {
    background: ${colors.GREEN5};
    color: ${colors.GREEN};
  }
`;
