import { FilterElementType } from "@utils/DynamicComponentUtils";
import {
  exposureStatusCheck,
  textFormat,
  upDataAtFormat,
} from "@utils/TableItemFormattingUtils";
import { ColDef } from "ag-grid-community";
import {
  CommonFilterDataType,
  CommonFormType,
  CommonPostDateType,
} from "src/types/common";

export interface FaqInitialValues extends CommonPostDateType {
  id?: string;
  title?: string;
  isExposed?: string;
  category1?: string;
  category2?: string;
  category3?: string;
}

export interface FaqFormType extends CommonFormType {
  className?: string;
}

export const faqData: CommonFilterDataType<
  FaqInitialValues,
  FaqFormType,
  ColDef
> = {
  formInitialValues: {
    category1: "",
    category2: "",
    category3: "",
    title: "",
    isExposed: "",
  },
  forms: [
    {
      type: FilterElementType.INPUT,
      name: "title",
      label: "제목",
      className: "faqTitle",
    },
    {
      type: FilterElementType.SELECT,
      name: "isExposed",
      label: "노출 상태",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "노출",
          value: true,
        },
        {
          label: "미노출",
          value: false,
        },
      ],
    },
  ],
  tableList: [
    {
      field: "id",
      headerName: "게시물ID",
      maxWidth: 120,
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "isExposed",
      headerName: "노출 상태",
      maxWidth: 120,
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: exposureStatusCheck,
    },
    {
      field: "category1",
      headerName: "대분류",
      maxWidth: 120,
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "category2",
      headerName: "중분류",
      maxWidth: 120,
      minWidth: 110,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "category3",
      headerName: "소분류",
      maxWidth: 120,
      minWidth: 120,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "title",
      headerName: "제목",
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "updatedAt",
      headerName: "업데이트 일시",
      minWidth: 180,
      maxWidth: 200,
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
    {
      field: "manager",
      headerName: "마지막 수정",
      pinned: "right",
      minWidth: 100,
      maxWidth: 120,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
  ],
};
