import { colors, typo } from "@styles/index";
import { Radio } from "antd";
import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { CommonOptionType } from "src/types/common";

interface RadioProps {
  value?: string | boolean;
  setValue?: React.Dispatch<React.SetStateAction<string | boolean>>;
  options?: CommonOptionType[];
  isButton?: boolean;
  onChange?: (value: any) => void;
  onClick?: () => void;
}

const CustomRadio: React.FC<RadioProps> = ({
  value,
  options,
  isButton,
  onChange,
  onClick,
}) => {
  // const [radioValue, setRadioValue] = useState<string | boolean>(
  //   options?.[0] ?? "",
  // );

  // const label =
  //   options?.filter(({ value: category }) => category === value)?.[0]?.label ??
  //   "";

  // const category =
  //   options?.filter(({ value: category }) => category === value)?.[0]?.value ??
  //   "";

  return (
    <div style={{ width: "100%" }}>
      <Radio.Group
        onChange={onChange}
        defaultValue={options?.[0].value}
        value={value}
      >
        {options?.map(({ label, value }) => {
          return (
            <StyledRadio key={label} value={value}>
              {label}
            </StyledRadio>
          );
        })}
      </Radio.Group>
      {isButton && (
        <ConnectButtonWrap>
          <Button
            buttonType="line_primary"
            buttonSize="regular"
            text="TEST"
            onClick={onClick}
          />
        </ConnectButtonWrap>
      )}
    </div>
  );
};

export default CustomRadio;

export const StyledRadio = styled(Radio)`
  color: ${colors.GRAY2};
  ${typo.BODY_8};
  display: inline-flex;
  align-items: center;

  .ant-radio {
    top: 0;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px #26b0eb22;
  }

  .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${colors.PRIMARY};
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;
  }

  .ant-radio-checked {
    ::after {
      border-color: ${colors.PRIMARY};
    }

    .ant-radio-input {
      :focus {
        border-color: ${colors.PRIMARY};
      }
    }

    .ant-radio-inner {
      background: ${colors.PRIMARY};
      border-color: ${colors.PRIMARY};

      ::after {
        border-color: ${colors.PRIMARY};
        background: ${colors.WHITE};
        left: 50%;
        top: 50%;
        transform: scale(0.5);
      }
    }
  }
`;

const ConnectButtonWrap = styled.div`
  position: relative;
  padding-top: 24px;
  margin-top: 20px;

  ::before {
    content: "";
    width: 100%;
    height: 1px;
    background: ${colors.GRAY10};
    position: absolute;
    top: 0;
  }
`;
