import { UploadSize } from "@components/atoms/Upload";
import { CommonElementType } from "@utils/DynamicComponentUtils";
import {
  CommonContentsRenderType,
  CommonCreateBodyKeyType,
  CommonDataType,
} from "src/types/common";

export type Category = "popup" | "banner";

export type ContentCategory = "notice" | "event" | "terms" | "url";

export interface ChargingServiceDetailInitValuesType {
  title: string;
  isExposed: boolean;
  thumbnail: string;
  contentCategory: string;
  content: string;
}

export interface ChargingServiceCommonContentsOptionType
  extends CommonContentsRenderType {
  placeholder?: string;
  isSizeQuestion?: boolean;
  size?: UploadSize;
}

export const ChargingServiceDetailData: CommonDataType<
  CommonCreateBodyKeyType,
  ChargingServiceDetailInitValuesType,
  ChargingServiceCommonContentsOptionType
> = {
  createBodyKey: ["title", "isExposed", "thumbnail", "content"],
  formInitialValues: {
    title: "",
    isExposed: false,
    thumbnail: "",
    contentCategory: "content",
    content: "",
  },
  contentsOption: [
    {
      label: "노출 상태",
      name: "isExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "제목",
      name: "title",
      type: CommonElementType.INPUT,
      placeholder: "제목을 입력해주세요",
    },
    {
      label: "썸네일 이미지",
      name: "thumbnail",
      type: CommonElementType.IMAGE_UPLOAD,
      size: "xsmall",
      isSizeQuestion: true,
    },
  ],
};
