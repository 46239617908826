import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import { BasicOptionType } from "@components/atoms/CheckBox";
import ContentBox from "@components/atoms/ContentBox";
import IconBox from "@components/atoms/IconBox";
import RenderBannerExposureLocationCheckbox from "@components/molecules/RenderBannerExposureLocationCheckbox";
import SizeNoticeBox from "@components/molecules/SizeNoticeBox";
import { ApiUrls } from "@constants/api-urls";
import regex from "@constants/regex";
import useCommonCreator from "@hooks/useCommonCreator";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { colors, typo } from "@styles/index";
import { RenderCommonElementByType } from "@utils/DynamicComponentUtils";
import { useFormik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { BannerCreateData, BannerCreateInitValuesType } from "./Data";
import RenderBannerConnectionList from "@components/molecules/RenderBannerConnectionList";

export const BannerConnectionCategory = [
  { label: "공지사항", value: "notices" },
  { label: "약관", value: "terms" },
  { label: "이벤트", value: "events" },
  { label: "외부 URL 추가", value: "url" },
];

export const PopupConnectionCategory = [
  { label: "공지사항", value: "notices" },
  { label: "약관", value: "terms" },
  { label: "이벤트", value: "events" },
  { label: "외부 URL 추가", value: "url" },
];

const BannerCreate: React.FC = () => {
  // Props
  const HEADER_TITLE = "배너 등록";
  const CONTENT_TITLE = "배너 내용";

  // Data
  const { contentsOption, createBodyKey, formInitialValues } = BannerCreateData;
  const [connectionKindList, setConnectionKindList] = useState<BasicOptionType>(
    PopupConnectionCategory[0],
  );

  // Hooks
  const { goBack } = useRouter();
  const { showConfirmModal, hideModal } = useGlobalModal();
  const { createMutate } = useCommonCreator({
    createUrl: ApiUrls.banner,
    createBodyKey,
  });

  // Formik
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      createMutate(values);
    },
  });

  const handleGoBackClick = () => {
    const { postEndAt, title, thumbnail, contentId, bannerExposureLocation } =
      formik.values;

    if (
      postEndAt ||
      title ||
      thumbnail ||
      contentId ||
      bannerExposureLocation.length > 0
    ) {
      showConfirmModal({
        title: `작성한 내용을 저장하지 않고\n 취소하시겠어요?`,
        firstButtonText: "닫기",
        secondButtonText: "확인",
        onConfirm: () => {
          hideModal();
          goBack();
        },
      });
    } else {
      goBack();
    }
  };

  const handleCreateContent = () => {
    formik.handleSubmit();
  };

  const handleBannerGrideDownLoad = () => {
    const pdfUrl = process.env.PUBLIC_URL + `/pdf/${"promotion-guide"}.pdf`;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `${"promotion-guide"}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const createButtonValidate = useCallback(() => {
    const {
      category,
      postStartAt,
      postEndAt,
      title,
      thumbnail,
      contentCategory,
      contentId,
      url,
      bannerExposureLocation,
    } = formik.values;

    const urlRegex = regex.url;
    const isUrl = url && urlRegex.test(url);

    const isContentUrl = contentCategory === "url";
    return (
      !category ||
      !postStartAt ||
      !postEndAt ||
      !title ||
      !thumbnail ||
      !contentCategory ||
      (isContentUrl ? !url || !isUrl : !contentId) ||
      (category === "banner" && !bannerExposureLocation.length)
    );
  }, [formik.values]);

  // 배너 종류 바꼈을때 연결 목록 변경 및 데이터 초기화
  useEffect(() => {
    const initValue: BannerCreateInitValuesType = {
      ...formik.initialValues,
      postStartAt: moment().format("YYYY-MM-DD"),
      category: "popup",
    };

    if (formik.values.category === "banner") {
      setConnectionKindList(BannerConnectionCategory[0]);
      initValue.category = "banner";
    } else if (formik.values.category === "popup") {
      setConnectionKindList(PopupConnectionCategory[0]);
      initValue.category = "popup";
    }

    formik.setValues(initValue);
  }, [formik.values.category]);

  return (
    <ContentsWrap>
      <HeaderWrap>
        <IconBox
          width={32}
          height={32}
          src={ICONS.ARROW_ICON.BACK}
          isCursorPointer
          onClick={handleGoBackClick}
        />
        <HeaderTitle>{HEADER_TITLE}</HeaderTitle>
      </HeaderWrap>

      <div>
        <ContentBox style={{ padding: 0 }}>
          <ContentsHeader>
            {CONTENT_TITLE}
            <Button
              buttonSize="small"
              buttonType="line_black"
              text="배너 가이드 다운받기"
              onClick={handleBannerGrideDownLoad}
            />
          </ContentsHeader>

          <ContentsMainWrap>
            {contentsOption?.map(
              (
                {
                  label,
                  name,
                  options,
                  hasKeywordToShowContent,
                  isSizeQuestion,
                  size,
                  ...res
                },
                _,
              ) => {
                if (hasKeywordToShowContent) {
                  if (formik.values.category === hasKeywordToShowContent) {
                    return (
                      <RenderBannerExposureLocationCheckbox
                        key={`${_}_contents`}
                        label={label}
                        formik={formik}
                        name={name}
                        checkBoxOptions={options as BasicOptionType[]}
                      />
                    );
                  }
                }

                if (!hasKeywordToShowContent) {
                  const bannerSizeCheck =
                    formik.values.category === "popup" ? size : "small";

                  return (
                    <React.Fragment key={`${_}_contents`}>
                      <InputWrap>
                        <LabelWrap>
                          <LabelInnerWrap>
                            <Label>{label || "LABEL"}</Label>
                            {isSizeQuestion && (
                              <SizeNoticeBox size={bannerSizeCheck} />
                            )}
                          </LabelInnerWrap>
                        </LabelWrap>
                        <InputContentsWrap>
                          {RenderCommonElementByType({
                            formik,
                            name,
                            options,
                            size: bannerSizeCheck,
                            ...res,
                          })}
                        </InputContentsWrap>
                      </InputWrap>
                    </React.Fragment>
                  );
                }
              },
            )}

            {RenderBannerConnectionList({
              connectionContentType: formik.values.category,
              formik,
              connectionKindList,
              setConnectionKindList,
            })}
          </ContentsMainWrap>
        </ContentBox>

        <FooterButtonWrap>
          <LeftButtonWrap></LeftButtonWrap>
          <RightButtonWrap>
            <Button
              text="취소"
              buttonType="line_black"
              onClick={handleGoBackClick}
            />
            <Button
              disabled={createButtonValidate()}
              text="등록하기"
              onClick={handleCreateContent}
            />
          </RightButtonWrap>
        </FooterButtonWrap>
      </div>
    </ContentsWrap>
  );
};

export default BannerCreate;

export const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

export const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

export const HeaderTitle = styled(MainHeaderTitle)`
  margin: 0;
`;

export const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

export const ContentTitle = styled(TableTitle)`
  margin: 0;
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

export const ContentsHeader = styled(ContentTitle)`
  display: flex;
  justify-content: space-between;
`;

export const ContentsMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 840px;
  margin: auto;
  padding: 52px 0 80px;
`;

export const InputWrap = styled.div`
  display: flex;
  gap: 12px;
  min-height: 48px;
  width: 100%;
`;

export const LabelWrap = styled.span`
  min-width: 200px;
  padding: 12px 0 12px;
`;

export const LabelInnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const Label = styled.p`
  color: ${colors.GRAY6};
  ${typo.HEADING_7};
`;

export const InputContentsWrap = styled.div<{ noti?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ noti }) =>
    noti &&
    css`
      flex-direction: column;
      gap: 12px;
    `}
`;

export const FooterButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const LeftButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;

export const RightButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;
