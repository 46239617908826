import { colors, typo } from "@styles/index";
import React from "react";
import styled, { css, CSSObject } from "styled-components";
import IconBox from "../IconBox";

type ButtonSize = "extra" | "lager" | "regular" | "small";

type ButtonType =
  | "primary"
  | "black"
  | "gray"
  | "line_primary"
  | "line_black"
  | "line_red"
  | "nonUnder_line"
  | "under_line"
  | "text";

interface ButtonProps {
  text?: string;
  full?: boolean;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  firstIconWidth?: number;
  firstIconHeight?: number;
  firstIconSrc?: string;
  style?: CSSObject;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text = "button",
      full,
      buttonType = "primary",
      style,
      disabled,
      buttonSize = "extra",
      className,
      firstIconWidth,
      firstIconHeight,
      firstIconSrc,
      onClick,
    },
    ref,
  ) => {
    return (
      <StyledButton
        type="button"
        className={className}
        ref={ref}
        full={full}
        style={style}
        buttonType={buttonType}
        buttonSize={buttonSize}
        disabled={disabled}
        onClick={onClick}
      >
        {firstIconSrc && (
          <IconBox
            width={firstIconWidth}
            height={firstIconHeight}
            src={firstIconSrc}
          />
        )}
        {text}
      </StyledButton>
    );
  },
);

Button.displayName = "Button";

const StyledButton = styled.button<{
  full?: boolean;
  buttonType: ButtonType;
  disabled?: boolean;
  buttonSize: ButtonSize;
}>`
  width: ${({ full }) => (full ? "100%" : "auto")};
  /* height: 100%; */
  border: none;
  cursor: pointer;
  transition: 0.3s ease;

  ${({ buttonSize, buttonType }) => {
    if (buttonType.includes("_line")) {
      switch (buttonSize) {
        case "extra": {
          return css`
            padding: 0;
            border-radius: 0;
            ${typo.BUTTON2};
            font-size: 16px;
            position: relative;

            ::after {
              content: "";
              display: inline-block;
              width: 100%;
              height: 1.5px;
              position: absolute;
              bottom: 3.5px;
              left: 0;
            }
          `;
        }
        case "lager": {
          return css`
            padding: 0;
            border-radius: 0;
            ${typo.BUTTON3};
            font-size: 14px;
            position: relative;

            ::after {
              content: "";
              display: inline-block;
              width: 100%;
              height: 1.5px;
              position: absolute;
              bottom: 2px;
              left: 0;
            }
          `;
        }
        case "regular": {
          return css`
            padding: 0;
            border-radius: 0;
            ${typo.BUTTON4};
            position: relative;

            ::after {
              content: "";
              display: inline-block;
              width: 70%;
              height: 1px;
              position: absolute;
              bottom: 2.5px;
              left: 50%;
              transform: translateX(-50%);
            }
          `;
        }
        case "small": {
          return css`
            padding: 0;
            border-radius: 0;
            ${typo.BUTTON5};
            position: relative;

            ::after {
              content: "";
              display: inline-block;
              width: 100%;
              height: 1.5px;
              position: absolute;
              bottom: 2.5px;
              left: 0;
            }
          `;
        }
      }
    } else {
      switch (buttonSize) {
        case "extra": {
          return css`
            padding: 17px 24px 15px;
            border-radius: 12px;
            ${typo.BODY_7};
          `;
        }
        case "lager": {
          return css`
            padding: 12px 20px 12px;
            border-radius: 10px;
            ${typo.BODY_7};
          `;
        }
        case "regular": {
          return css`
            padding: 8px 12px 6px;
            border-radius: 8px;
            ${typo.BODY_10};
          `;
        }
        case "small": {
          return css`
            padding: 7px 10px 5px;
            border-radius: 7px;
            ${typo.BODY_10};
          `;
        }
      }
    }
  }}

  ${({ buttonType }) => {
    switch (buttonType) {
      case "primary": {
        return css`
          color: ${colors.WHITE};
          background-color: ${colors.PRIMARY};

          :hover {
            background-color: ${colors.PRIMARY2};
          }

          :active {
            background-color: ${colors.PRIMARY1};
          }

          :disabled {
            background-color: ${colors.PRIMARY5};
            /* color: ${colors.GRAY7}; */
          }
        `;
      }
      case "gray": {
        return css`
          color: ${colors.GRAY4};
          background-color: ${colors.GRAY10};

          :hover {
            background-color: ${colors.GRAY9};
          }

          :active {
            background-color: ${colors.GRAY8};
          }

          :disabled {
            background-color: ${colors.GRAY10};
            color: ${colors.GRAY7};
          }
        `;
      }
      case "line_primary": {
        return css`
          color: ${colors.PRIMARY};
          border: 1px solid ${colors.PRIMARY};

          background-color: ${colors.WHITE};

          :hover {
            border-color: ${colors.PRIMARY2};
          }

          :active {
            border-color: ${colors.PRIMARY1};
            color: ${colors.PRIMARY1};
          }

          :disabled {
            border-color: ${colors.GRAY7};
            color: ${colors.GRAY7};
          }
        `;
      }
      case "line_black": {
        return css`
          color: ${colors.GRAY4};
          border: 1px solid ${colors.GRAY8};

          background-color: ${colors.WHITE};

          :hover {
            border-color: ${colors.GRAY7};
          }

          :active {
            border-color: ${colors.GRAY6};
          }

          :disabled {
            border-color: ${colors.GRAY7};
            color: ${colors.GRAY7};
          }
        `;
      }
      case "line_red": {
        return css`
          color: ${colors.RED2};
          border: 1px solid ${colors.RED2};

          background-color: ${colors.WHITE};

          :hover {
            border-color: ${colors.RED};
          }

          :active {
            color: ${colors.RED1};
            border-color: ${colors.RED1};
          }

          :disabled {
            border-color: ${colors.RED4};
            color: ${colors.RED4};
          }
        `;
      }
      case "under_line": {
        return css`
          color: ${colors.GRAY3};
          background-color: ${colors.WHITE};

          ::after {
            background-color: ${colors.GRAY3};
          }

          :hover {
            border-color: ${colors.GRAY2};

            ::after {
              background: ${colors.GRAY2};
            }
          }

          :active {
            border-color: ${colors.BLACK};

            ::after {
              background: ${colors.BLACK};
            }
          }

          :disabled {
            color: ${colors.GRAY7};

            ::after {
              background: ${colors.GRAY7};
            }
          }
        `;
      }
      case "text": {
        return css`
          background: ${colors.WHITE};
        `;
      }
    }
  }}

    ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: auto;
      `;
    }
  }}
`;

export default Button;
