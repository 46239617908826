import { ICONS } from "@assets/icons";
import IconBox from "@components/atoms/IconBox";
import useGlobalModal from "@hooks/useGlobalModal";
import { colors, typo } from "@styles/index";
import styled from "styled-components";
import BaseModal from "../BaseModal";
import {
  CloseIconWrap,
  HeaderTitle,
  ModalHeader,
} from "../OrderManagementModal/style";
import { ModalType } from "@store/modal";
import { UpdateHistory } from "@apis/faq/types";
import DataEmpty from "@components/atoms/DateEmpty";
import { stringToDateTimerFormatter } from "@utils/string-utils";

export type ReasonCategoryType = "notice" | "faq" | "terms";

export interface ReasonModificationModalProps {
  modalType?: ModalType;
  category: ReasonCategoryType;
  updateHistory?: UpdateHistory[];
  onClose?: () => void;
}

const ReasonModificationModal = ({
  modalType = "ReasonModificationModal",
  category,
  updateHistory,
  onClose,
}: ReasonModificationModalProps) => {
  const { hideModal } = useGlobalModal();

  const categoryTitleCheck = (category: string) => {
    switch (category) {
      case "notice":
        return "공지사항";
      case "faq":
        return "FAQ";
      case "terms":
        return "약관";
      default:
        return "공지사항";
    }
  };

  const itemCard = () => {
    return (
      <>
        {updateHistory?.length !== 0 ? (
          updateHistory?.map((update, index) => {
            return (
              <ItemCardWrap key={index}>
                <div>
                  <span className="manger">{update.manager}</span>
                  <span className="reasonModification">{update.reason}</span>
                </div>
                <span className="updateAt">
                  {stringToDateTimerFormatter(update.updatedAt)}
                </span>
              </ItemCardWrap>
            );
          })
        ) : (
          <DataEmpty style={{ paddingTop: 170 }} />
        )}
      </>
    );
  };

  return (
    <BaseModal
      isOpen
      modalWidth={50}
      disableModalPadding
      modalType={modalType}
      handleClose={onClose || hideModal}
      contentsStyle={{ height: 560 }}
    >
      <div style={{ height: "100%" }}>
        <ModalHeader>
          <HeaderTitle>{`${categoryTitleCheck(
            category,
          )} 수정사유 확인`}</HeaderTitle>
          <CloseIconWrap>
            <IconBox
              src={ICONS.MODAL_ICON.CLOSE}
              width={24}
              height={24}
              isCursorPointer
              onClick={onClose || hideModal}
            />
          </CloseIconWrap>
        </ModalHeader>

        <ModalContents>{itemCard()}</ModalContents>
      </div>
    </BaseModal>
  );
};

export default ReasonModificationModal;

const ModalContents = styled.div`
  min-height: 300px;
  max-height: 472px;

  overflow-y: auto;
  overflow-x: hidden;

  > p {
    padding: 32px 32px;
    color: ${colors.GRAY6};
  }
`;

const ItemCardWrap = styled.div`
  /*  */
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 16px 32px;

  > div {
    display: flex;
    flex-direction: column;

    .manger {
      ${typo.HEADING_7}
    }

    .reasonModification {
      ${typo.BODY_7}
    }
  }

  .updateAt {
    ${typo.BODY_8}
    color: ${colors.GRAY6}
  }
`;
