import Button from "@components/atoms/Button";
import IconBox from "@components/atoms/IconBox";
import { colors, typo } from "@styles/index";
import React from "react";
import styled, { css } from "styled-components";
import { AppVersionDetailData, AppVersionDetailInitValuesType } from "./Data";
import { useFormik } from "formik";
import { ApiUrls } from "@constants/api-urls";
import useFetchDetailData from "@hooks/useFetchDetailData";
import useCommonEdit from "@hooks/useCommonEdit";
import useCommonDelete from "@hooks/useCommonDelete";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import ContentBox from "@components/atoms/ContentBox";
import { RenderCommonElementByType } from "@utils/DynamicComponentUtils";
import { AppVersionDetailResponse } from "@apis/appVersion/types";
import isCheckValueExistence from "@utils/check-value-existence";
import { compareObjects } from "@utils/data-fns";
import { ICONS } from "@assets/icons";

const AppVersionDetail: React.FC = () => {
  const HEADER_TITLE = "앱 버전 상세";
  const CONTENT_TITLE = "버전 상세";
  const SUB_CONTENT_TITLE = "버전 내용";
  const GIRD_COLUMN = 3;

  // Data
  const {
    createBodyKey,
    formInitialValues,
    contentsOption,
    secondContentsOption,
  } = AppVersionDetailData;

  // Formik
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      editMutation(values);
    },
  });

  // Hooks
  const { query, goBack, pathname } = useRouter();
  const { hideModal, showConfirmModal } = useGlobalModal();
  const id = query && query.id ? query.id : "";
  const { initData } = useFetchDetailData<AppVersionDetailResponse>({
    id: id,
    queryKey: "eventDetail",
    fetchUrl: ApiUrls.appVersionDetail,
    formik,
  });
  const { editMutation } = useCommonEdit<AppVersionDetailInitValuesType>({
    editUrl: ApiUrls.appVersionDetail,
    createBodyKey,
  });
  const { handleDelete } = useCommonDelete({
    deleteUrl: ApiUrls.appVersionDelete,
  });

  const handleGoBackClick = () => {
    if (JSON.stringify(initData) !== JSON.stringify(formik?.values)) {
      showConfirmModal({
        title: `작성한 내용을 저장하지 않고\n 취소 하시겠어요?`,
        firstButtonText: "닫기",
        secondButtonText: "확인",
        onConfirm: () => {
          hideModal();
          goBack();
        },
      });
    } else {
      goBack();
    }
  };

  const handleEditContent = () => {
    showConfirmModal({
      title: `저장하시겠어요?`,
      firstButtonText: "닫기",
      secondButtonText: "저장하기",
      onConfirm: () => {
        localStorage.removeItem("previewData");
        hideModal();
        formik?.handleSubmit();
      },
    });
  };

  const handleDeleteContent = () => {
    showConfirmModal({
      title: `삭제하시겠어요?`,
      firstButtonText: "닫기",
      secondButtonText: "삭제하기",
      onConfirm: () => {
        handleDelete(id);
        hideModal();
      },
    });
  };

  const isEditButtonValidate = () => {
    if (!initData) return true;
    const { values: formikValues } = formik;

    return (
      !isCheckValueExistence({
        formikValues,
        pathname,
        validateKey: createBodyKey,
      }) || compareObjects(initData, formikValues)
    );
  };

  return (
    <ContentsWrap>
      <HeaderWrap>
        <IconBox
          width={32}
          height={32}
          src={ICONS.ARROW_ICON.BACK}
          isCursorPointer
          onClick={handleGoBackClick}
        />
        <HeaderTitle>{HEADER_TITLE}</HeaderTitle>
      </HeaderWrap>

      <div>
        <InnerContentsWrap>
          <ContentBox style={{ padding: 0 }}>
            <StyledContentTitle>{CONTENT_TITLE}</StyledContentTitle>
            <InfoBoxWrap>
              <InfoBox girdColumn={GIRD_COLUMN}>
                {contentsOption?.map(({ label, ...res }) => {
                  return (
                    <CustomInputWrap key={label}>
                      <CustomLabel>{label}</CustomLabel>
                      {RenderCommonElementByType({
                        formik,
                        ...res,
                      })}
                    </CustomInputWrap>
                  );
                })}
              </InfoBox>
            </InfoBoxWrap>
          </ContentBox>

          <ContentBox style={{ padding: 0 }}>
            <StyledContentTitle>{SUB_CONTENT_TITLE}</StyledContentTitle>
            <ContentsMainWrap>
              {secondContentsOption?.map(({ label, ...res }, _) => {
                return (
                  <React.Fragment key={`${_}_contents`}>
                    <InputWrap>
                      <LabelWrap>
                        <LabelInnerWrap>
                          <Label>{label || "LABEL"}</Label>
                        </LabelInnerWrap>
                      </LabelWrap>
                      <InputContentsWrap>
                        {RenderCommonElementByType({
                          formik,
                          ...res,
                        })}
                      </InputContentsWrap>
                    </InputWrap>
                  </React.Fragment>
                );
              })}
            </ContentsMainWrap>
          </ContentBox>
        </InnerContentsWrap>

        <FooterButtonWrap>
          <LeftButtonWrap>
            <Button
              text="삭제"
              buttonType="line_red"
              onClick={handleDeleteContent}
            />
          </LeftButtonWrap>
          <RightButtonWrap>
            <Button
              text="취소"
              buttonType="line_black"
              onClick={handleGoBackClick}
            />
            <Button
              text="저장하기"
              disabled={isEditButtonValidate()}
              onClick={handleEditContent}
            />
          </RightButtonWrap>
        </FooterButtonWrap>
      </div>
    </ContentsWrap>
  );
};

export default AppVersionDetail;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const HeaderTitle = styled(MainHeaderTitle)`
  margin: 0;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const InnerContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ContentTitle = styled(TableTitle)`
  margin: 0;
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const InfoBoxWrap = styled.div`
  padding: 40px;
`;

const CustomInputWrap = styled.div<{ flex?: number; minWidth?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const CustomLabel = styled.label`
  min-width: 130px;
  padding: 12px;
  background-color: ${colors.GRAY11};
  color: ${colors.GRAY4};
  ${typo.BODY_8};
`;

const StyledContentTitle = styled(ContentTitle)`
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const FilterBox = styled.div<{ girdColumn?: number }>`
  width: 100%;
  margin-bottom: 12px;
  border-top: 1px solid ${colors.GRAY9};

  display: grid;
  grid-template-columns: repeat(${({ girdColumn }) => girdColumn}, 1fr);

  .faqTitle {
    grid-column: 1 / 3;
  }
`;

const InfoBox = styled(FilterBox)`
  margin-bottom: unset;
`;

const ContentsMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 840px;
  margin: auto;
  padding: 52px 0 80px;
`;

const InputWrap = styled.div`
  display: flex;
  gap: 12px;
  min-height: 48px;
  width: 100%;
`;

const LabelWrap = styled.span`
  min-width: 200px;
  padding: 12px 0 12px;
`;

const LabelInnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const Label = styled.p`
  color: ${colors.GRAY6};
  ${typo.HEADING_7};
`;

const InputContentsWrap = styled.div<{ noti?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ noti }) =>
    noti &&
    css`
      flex-direction: column;
      gap: 12px;
    `}
`;

const FooterButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const LeftButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;

const RightButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;
