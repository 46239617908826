import { ModalType } from "@store/modal";
import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

interface BaseModalProps {
  children?: React.ReactNode;
  isOpen: boolean;
  handleClose?: () => void;
  modalWidth?: number;
  disableModalPadding?: boolean;
  disableModalInnerGap?: boolean;
  modalType: ModalType;
  contentsStyle?: React.CSSProperties;
}

const BaseModal: React.FC<BaseModalProps> = ({
  children,
  isOpen,
  modalWidth,
  disableModalPadding,
  disableModalInnerGap,
  modalType = "TwoButtonModal",
  contentsStyle,
  handleClose,
}) => {
  const customStyles = {
    overlay: {
      background: "#00000040",
      zIndex: 9999,
    },
    content: contentsStyle,
  };
  return (
    <StyledModal
      modalType={modalType}
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      modalWidth={modalWidth}
      disableModalPadding={disableModalPadding}
      disableModalInnerGap={disableModalInnerGap}
      ariaHideApp={false}
    >
      {children}
    </StyledModal>
  );
};

export default BaseModal;

const StyledModal = styled(ReactModal)<{
  modalWidth?: number;
  disableModalPadding?: boolean;
  modalType: ModalType;
  disableModalInnerGap?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: ${({ modalWidth }) => (modalWidth ? modalWidth : "22.5")}rem;
  background: white;
  padding: ${({ disableModalPadding }) => (disableModalPadding ? 0 : "24px")};
  border-radius: 16px;
  box-shadow: 0 4px 32px rgba(53, 58, 63, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ disableModalInnerGap }) => (disableModalInnerGap ? "0" : "24")}px;
  z-index: 2;
`;
