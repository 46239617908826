import { Row } from "@apis/common/types";
import request from "@network/index";
import {
  TermsDetailResponse,
  TermsHistoryCreateRequest,
  TermsHistoryDetailResponse,
  TermsHistoryUpdateRequest,
} from "./types";

//  상세 조회 API
export const requestTermsDetail = async (params: any) => {
  const { data } = await request<void, Row<TermsDetailResponse>>({
    method: "get",
    url: `/terms/history?${new URLSearchParams(params as any).toString()}`,
  });

  return data.row;
};

// 약관 이력 조회 API
export const requestTermsHistory = async (params?: number) => {
  const { data } = await request<void, Row<TermsHistoryDetailResponse>>({
    method: "get",
    url: `/terms/${params}`,
  });

  return data.row;
};

// 약관 이력 생성 API
export const requestTermsHistoryCreate = async (
  body: TermsHistoryCreateRequest,
) => {
  // body값 체크
  await request<TermsHistoryCreateRequest, void>({
    method: "post",
    url: `/terms/history`,
    requestBody: body,
  });
};

// 약관 이력 수정 API
export const requestTermsHistoryUpdate = async (
  body: TermsHistoryUpdateRequest,
) => {
  await request<TermsHistoryUpdateRequest, void>({
    method: "put",
    url: `/terms/history`,
    requestBody: body,
  });
};

// 약관 이력 삭제 API
export const requestTermsHistoryDelete = async (params: number) => {
  await request<number, void>({
    method: "delete",
    url: `/terms/${params}`,
  });
};
