import { requestImageUpload } from "@apis/common";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import useRouter from "@hooks/useRouter";
import { imageUploadCategoryCheck } from "@utils/data-fns";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { FormikContextType } from "formik";
import React from "react";
import styled from "styled-components";
import { editorConfiguration } from "./setting";

interface EditorProps {
  formik?: FormikContextType<any>;
  initData?: string;
  placeHolder?: string;
  onChange?: (data: string) => void;
}

const Editor: React.FC<EditorProps> = ({
  formik,
  initData,
  placeHolder,

  onChange,
}) => {
  const { pathname } = useRouter();

  const customUploadAdapter = (loader: any) => {
    return {
      upload: async () => {
        const imageFile = await loader.file;
        const category = imageUploadCategoryCheck(pathname);
        const uploadData = { imageFile, category };
        const imageUrl = await requestImageUpload(uploadData);
        return { default: imageUrl };
      },
    };
  };

  const handleImageupload = (editor: any) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any,
    ) => {
      return customUploadAdapter(loader);
    };
  };

  let editorOption = editorConfiguration;

  if (placeHolder) {
    editorOption = {
      ...editorConfiguration,
      placeholder: placeHolder,
    };
  }

  return (
    <EditWrap>
      <CKEditor
        editor={ClassicEditor}
        config={editorOption}
        onReady={(editor: any) => {
          handleImageupload(editor);
        }}
        data={initData}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          if (onChange) {
            onChange(data);
          } else {
            formik?.setFieldValue("content", data);
          }
        }}
        onBlur={() => {}}
        onFocus={() => {}}
      />
    </EditWrap>
  );
};

export default Editor;

export const EditWrap = styled.div`
  margin-top: 20px;
  min-height: 372px;
`;
