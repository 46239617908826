import { colors } from "@styles/index";
import React from "react";
import styled, { css } from "styled-components";

interface ToggleProps {
  state?: boolean;
  onClick?: () => void;
}

const Toggle: React.FC<ToggleProps> = ({ state, onClick }) => {
  return <StyledToggle state={state} onClick={onClick} />;
};

export default Toggle;

export const StyledToggle = styled.span<{ state?: boolean }>`
  display: inline-block;
  width: 48px;
  height: 24px;
  border-radius: 16px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;

  ::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: ${colors.WHITE};
    border-radius: 50%;
    position: absolute;
    /* box-shadow: 0px 2px 16px rgba(53, 58, 63, 0.04); */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
    top: 2px;
  }

  ${({ state }) => {
    if (state) {
      return css`
        background: ${colors.PRIMARY};

        ::after {
          transform: translateX(calc(100% + 6px));
        }
      `;
    } else {
      return css`
        background: ${colors.GRAY9};

        ::after {
          left: 2px;
        }
      `;
    }
  }}
`;
